import api from './api'
import { TAluno } from '../types/aluno'
import { TUser } from '../types/user'

export interface IAlunoServices {
  upsertAluno: (aluno: object | undefined, responsavel_aluno: TUser, mode: string) => Promise<any>
  updateAluno: (aluno: object | undefined, responsavel_aluno: TUser) => Promise<any>
  cancelarAssinatura: (aluno: object | undefined, responsavel_aluno: string) => Promise<any>
  ativarAssinatura: (aluno: object | undefined, responsavel_aluno: string) => Promise<any>
  resendCode: (aluno: object | undefined, responsavel_aluno: string) => Promise<any>
}

export const alunoServices = () => ({
  upsertAluno: async (aluno: TAluno, responsavel_aluno: TUser, mode: string) => {
    try {
      const quantity =
        (responsavel_aluno.subscription?.quantity ?? 1) +
        (responsavel_aluno.subscription?.status == 'paused' ? 0 : 1)

      const payload = {
        responsavel_aluno: responsavel_aluno._id,
        versao_termo: responsavel_aluno.versao_termo,
        nr_cpf: responsavel_aluno.nr_cpf,
        price_id: responsavel_aluno.price_id,
        quantity
      }

      const { data } =
        mode === 'create'
          ? await api.post('/dulce/b2c/assinatura', { ...payload, aluno })
          : await api.put(`/dulce/b2c/${responsavel_aluno._id}/aluno/${aluno._id}`, aluno)
      return data?.response
    } catch (error: any) {
      return await Promise.reject(error?.response?.data)
    }
  },
  updateAluno: async (aluno: any) => {
    try {
      const { data } = await api.put(
        `/dulce/b2c/${aluno.responsavel_aluno}/aluno/${aluno._id}`,
        aluno
      )
      return data?.response
    } catch (error: any) {
      return await Promise.reject(error?.response?.data)
    }
  },

  resendCode: async (aluno: TAluno, responsavel_aluno: string) => {
    try {
      const { data } = await api.post(
        `/dulce/b2c/${responsavel_aluno}/aluno/${aluno._id}/reenviar-credenciais`,
        {}
      )
      return data?.response
    } catch (error: any) {
      return await Promise.reject(error?.response?.data)
    }
  },

  cancelarAssinatura: async (aluno: TAluno | undefined, responsavel_aluno: string) => {
    try {
      const { data } = await api.post(`/dulce/b2c/cancelar-assinatura/`, {
        responsavel_aluno,
        aluno_id: aluno?._id
      })
      return data?.response
    } catch (error: any) {
      return await Promise.reject(error?.response?.data ?? error?.message)
    }
  },

  ativarAssinatura: async (aluno: TAluno | undefined, responsavel_aluno: string) => {
    try {
      const { data } = await api.post(`/dulce/b2c/ativar-assinatura/`, {
        responsavel_aluno,
        aluno_id: aluno?._id
      })
      return data?.response
    } catch (error: any) {
      return await Promise.reject(error?.response?.data ?? error?.message)
    }
  }
})

export default <IAlunoServices>alunoServices()
