import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText
} from '@mui/material'
import Base from '../../layout/Base'
import React, { useState } from 'react'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import planServices from '../../services/planServices'
import couponServices from '../../services/couponServices'
import { useMutation } from 'react-query'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'

const schema = z.object({
  cardNumber: z.string().min(19, 'O número do cartão deve ter no mínimo 16 caracteres'),
  cardExpiration: z.string().min(1, 'A data de validade deve ter 6 caracteres no formato MM/AAAA'),
  cardCVV: z.string().min(3, 'O CVV deve ter no mínimo 3 caracteres'),
  cardName: z.string().min(6, 'O nome do cartão deve ter no mínimo 6 caracteres'),
  cardCPF: z.string().min(11, 'O CPF deve ter no mínimo 11 caracteres'),
  promotionCode: z.string().optional()
})

type Cupom = { promotion_code: String; beneficio: String; descricao: String }

export const PaymentData = () => {
  const { setUser, setToken } = useContext(AuthContext)
  const router = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const responsavel_aluno = queryParams.get('responsavel_aluno')
  const [cpf, setCpf] = useState('')
  const plan = queryParams.get('plan')
  const price = queryParams.get('price')
  const count = queryParams.get('count')
  const plantitle = queryParams.get('plantitle')
  const [checked, setChecked] = useState(false)
  const [promotionCode, setPromotionCode] = useState('')
  const [cupom, setCupom] = useState<Cupom>()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  type Schema = z.infer<typeof schema>

  const createSubscriptionMutation = useMutation(async (data: Schema) => {
    try {
      const { response } = await planServices.createSubscription(data)
      if (response.responsavel) {
        localStorage.setItem('@user', JSON.stringify(response.responsavel))
        setUser(response.responsavel)
      }

      if (response.access_token) {
        localStorage.setItem('@token', response.access_token)
        setToken(response.access_token)
      }
      router(`/payment-sucess?responsavel_aluno=${responsavel_aluno}&cpf=${cpf}`)
    } catch (error: any) {
      console.log(error)
      enqueueSnackbar(error?.message, { variant: 'error' })
    }
  })

  const createCouponMutation = useMutation(async (coupon: any) => {
    try {
      setLoading(true)
      const { response } = await couponServices.verifyCoupon(coupon)
      return response
    } catch (error: any) {
      console.log(error)
      if (error?.message) enqueueSnackbar(error?.message, { variant: 'error' })
    } finally {
      setLoading(false)
    }
  })

  const handleSubmitForm = (event: any) => {
    const payload = {
      responsavel_aluno: responsavel_aluno,
      versao_termo: 1,
      nr_cpf: event.cardCPF,
      price_id: plan,
      quantity: count,
      payment_type: 'card',
      promotion_code: cupom?.promotion_code,
      payment_data: {
        number: event.cardNumber.trim().replace(/\s/g, ''),
        exp_month: event.cardExpiration.split('/')[0],
        exp_year: event.cardExpiration.split('/')[1],
        cvc: event.cardCVV
      }
    }

    checked
      ? createSubscriptionMutation.mutate(payload as any)
      : enqueueSnackbar('Você precisa aceitar os termos de consentimento', { variant: 'error' })
  }

  const [paymentMethod, setPaymentMethod] = useState('credit')

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(event.target.value)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleTermClick = (event: any) => {
    window.open('/terms-of-use', '_blank')
    // Adicionar aqui a lógica para exibir o termo de consentimento
  }

  const ChoosePlan = () => {
    return (
      <Box
        sx={{
          width: '100%',
          height: '53px',
          background: '#EAF2FF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '12px',
          mt: '1rem'
        }}>
        <Typography fontWeight={700} marginLeft={3}>
          {count && price
            ? `${Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                parseInt(count) * parseFloat(price)
              )} / ${plantitle}`
            : 'Valores inválidos'}
        </Typography>
        <Button
          sx={{ marginRight: '1rem' }}
          onClick={() => {
            router(`/choose-plan?responsavel_aluno=${responsavel_aluno}`)
          }}>
          Trocar
        </Button>
      </Box>
    )
  }

  const Terms = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginTop: '1rem' }}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleCheckboxChange} color="primary" />}
          label={
            <Typography sx={{ fontSize: '0.7rem', fontWeight: 700 }}>
              Declaro que li e concordo com o{' '}
              <span
                onClick={handleTermClick}
                style={{
                  color: '#1976D2',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  zIndex: 1
                }}>
                termo de uso
              </span>{' '}
              do aplicativo
            </Typography>
          }
        />
      </Box>
    )
  }

  const formatCardNumber = (value: string) => {
    value = value.replace(/\D/g, '') // Remove todos os caracteres não numéricos
    value = value.replace(/(\d{4})(?=\d)/g, '$1 ') // Adiciona um espaço a cada 4 dígitos
    return value
  }

  return (
    <Base>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '400px',
          gap: '0.1rem',
          padding: '1rem'
        }}>
        <Avatar src="/images/dulce-logo.png" />
        <Typography sx={{ fontSize: '0.8rem' }}>Passo 4 de 4</Typography>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
          Informe os dados do seu cartão
        </Typography>
        <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
          <TextField
            label="Número do Cartão"
            {...register('cardNumber')}
            type="text"
            required
            fullWidth
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CreditCardIcon sx={{ color: '#687192' }} />
                </InputAdornment>
              )
            }}
            onChange={(event) => {
              //limite caracteres
              if (event.target.value.length > 19) {
                event.target.value = event.target.value.slice(0, 19)
              }
              //formata o número do cartão
              event.target.value = formatCardNumber(event.target.value)
            }}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber?.message}
          />

          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <FormControl sx={{ width: '100%' }}>
              <DatePicker
                label={'Data de Validade'}
                format="MM/YYYY"
                views={['month', 'year']}
                sx={{
                  width: '100%',
                  mt: '0.5rem'
                }}
                onChange={(date: any) => {
                  const { $y, $M } = date
                  setValue('cardExpiration', `${$M + 1}/${$y}`)
                }}
              />
              <FormHelperText error={!!errors.cardExpiration}>
                {errors.cardExpiration?.message}
              </FormHelperText>
            </FormControl>
            {/* <TextField
              label="Data de Validade"
              {...register('cardExpiration')}
              type="text"
              required
              fullWidth
              margin="dense"
              error={!!errors.cardExpiration}
              helperText={errors.cardExpiration?.message}
              onChange={(event) => {
                // Limite de caracteres
                if (event.target.value.length > 7) {
                  event.target.value = event.target.value.slice(0, 7)
                }
                // Formata a data de validade
                event.target.value = event.target.value
                  .replace(/\D/g, '') // Remove todos os caracteres não numéricos
                  .replace(/(\d{2})(\d{0,4})/, '$1/$2') // Adiciona uma barra após os primeiros dois dígitos
              }}
            /> */}
            <TextField
              label="CVV"
              {...register('cardCVV')}
              type="text"
              required
              fullWidth
              margin="dense"
              error={!!errors.cardCVV}
              helperText={errors.cardCVV?.message}
              onChange={(event) => {
                // Limite de caracteres
                if (event.target.value.length > 3) {
                  event.target.value = event.target.value.slice(0, 3)
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <HelpOutlineIcon sx={{ color: '#687192' }} />
                  </InputAdornment>
                )
              }}
            />
          </Box>

          <TextField
            label="Nome do Titular"
            type="text"
            required
            fullWidth
            margin="dense"
            {...register('cardName')}
            error={!!errors.cardName}
            helperText={errors.cardName?.message}
          />
          <TextField
            label="CPF do Titular"
            type="number"
            required
            fullWidth
            margin="dense"
            {...register('cardCPF')}
            error={!!errors.cardCPF}
            helperText={errors.cardCPF?.message}
            onChange={(event) => {
              // Limite de caracteres
              if (event.target.value.length > 14) {
                event.target.value = event.target.value.slice(0, 14)
              }
              setCpf(event.target.value)
            }}
          />
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: '#687192',
              fontWeight: 700,
              marginTop: '1rem'
            }}>
            Qual será a forma de pagamento?
          </Typography>

          <RadioGroup
            aria-label="Forma de Pagamento"
            name="paymentMethod"
            value={paymentMethod}
            onChange={handlePaymentMethodChange}>
            <FormControlLabel
              value="credit"
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: '0.875rem', fontWeight: 700, color: '#687192' }}>
                  Cartão de Crédito
                </Typography>
              }
            />
            <FormControlLabel
              value="debit"
              control={<Radio />}
              disabled
              label={
                <Typography sx={{ fontSize: '0.875rem', fontWeight: 700, color: '#687192a3' }}>
                  Cartão de Débito
                </Typography>
              }
            />
          </RadioGroup>
          <Typography
            sx={{
              fontSize: '0.875rem',
              color: '#687192',
              fontWeight: 700,
              marginTop: '1rem'
            }}>
            Possui algum cupom de desconto? Insira abaixo
          </Typography>
          <TextField
            label="Cupom"
            type="text"
            fullWidth
            margin="dense"
            value={promotionCode || ''}
            {...register('promotionCode')}
            onChange={(event) => setPromotionCode(event.target.value)}
            onBlur={async () => {
              if (promotionCode !== '')
                setCupom(await createCouponMutation.mutateAsync(promotionCode))
              else setCupom({ descricao: '', beneficio: '', promotion_code: '' })
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography fontWeight={600} sx={{ color: '#2E7D32' }}>
                    {cupom?.beneficio}
                  </Typography>
                </InputAdornment>
              )
            }}
          />
          {cupom && cupom.descricao && (
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: cupom.promotion_code ? '#687192' : 'red',
                mb: 2
              }}>
              {cupom.descricao}
            </Typography>
          )}
          <ChoosePlan />

          <Terms />

          <Button
            variant="contained"
            sx={{ marginTop: '1rem', width: '100%', mb: 10 }}
            disabled={loading}
            type="submit">
            {createSubscriptionMutation.isLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : count && price ? (
              `Pagar ${Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                parseInt(count) * parseFloat(price)
              )}`
            ) : (
              'Valores inválidos'
            )}
          </Button>
        </form>
      </Box>
    </Base>
  )
}
