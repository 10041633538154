import { Box, Button, Typography, useMediaQuery, Chip } from '@mui/material'
import EditIcon from '@mui/icons-material/EditOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import visaIcon from '../../assets/visa.png'
import masterIcon from '../../assets/master.png'
import { ChangePaymentModal } from '../ChangePaymentModal'
import React from 'react'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'
import { TAluno } from '../../types/aluno'

export const PaymentMethods = () => {
  const { user } = useContext(AuthContext)
  const expYear = user?.subscription?.payment_method?.exp_year
  const yearLastTwoDigits = expYear ? expYear.toString().slice(-2) : ''
  const month = user?.subscription?.payment_method?.exp_month || ''
  const brand = user?.subscription?.payment_method?.brand
  const status = user?.subscription?.status
  const next_billing_cycle = user?.subscription?.next_billing_cycle
  const last4 = user?.subscription?.payment_method?.last4
  const quantity = user?.subscription.status == 'paused' ? 0 : user?.subscription?.quantity
  const plano = user?.subscription?.nickname
  const valor = user?.subscription?.unit_amount
  const proximaFatura = user?.subscription?.current_invoice?.total
  const isMobile = useMediaQuery('(max-width: 600px)')
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  return (
    <Box
      sx={{
        minWidth: '200px',
        maxWidth: '330px',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        p: '12px'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1
        }}>
        <Typography fontSize={isMobile ? '0.675rem' : '0.9rem'} fontWeight={700}>
          Forma de pagamento
        </Typography>
        <Button
          variant="text"
          endIcon={<EditIcon />}
          sx={{
            color: '#1976D2',
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 700
          }}
          onClick={() => setIsModalOpen(true)}>
          {isMobile ? '  ' : 'Editar'}
        </Button>
        <ChangePaymentModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '139px',
          background: 'linear-gradient(135deg, #335485 0%, #954EA1 100%)',
          borderRadius: '15px',
          padding: '16px',
          minWidth: '230px'
        }}>
        {/* bloco cima */}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {['visa', 'mastercard'].includes(brand) ? (
            <img
              src={brand == 'visa' ? visaIcon : masterIcon}
              alt=""
              style={{ width: 'auto', height: brand == 'visa' ? '20px' : '38px' }}
            />
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '2px',
              height: '20px',
              alignItems: 'center'
            }}>
            <Typography fontSize={15} color={'#fff'}>
              {quantity}
            </Typography>
            <AccountCircleIcon sx={{ color: '#fff', fontSize: '18px' }} />
          </Box>
        </Box>
        {/* bloco baixo */}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography fontSize={isMobile ? '1.25rem' : '1.10rem'} color={'#fff'} fontWeight={700}>
            {last4 ? `**** **** **** ${last4}` : '**** **** **** ****'}
          </Typography>
          <Typography fontSize={15} color={'#fff'}>
            {month && yearLastTwoDigits ? `${month}/${yearLastTwoDigits}` : ''}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
          mt: 2
        }}>
        <Typography fontSize={isMobile ? '0.6rem' : '0.8rem'}>Assinaturas:</Typography>
        <Typography fontSize={isMobile ? '0.6rem' : '0.8rem'}>
          {quantity} x {plano}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1
        }}>
        <Typography fontSize={isMobile ? '0.6rem' : '0.8rem'}>Total da assinatura:</Typography>
        <Typography fontSize={isMobile ? '0.6rem' : '0.8rem'}>
          {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
            (valor * quantity) / 100
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <Typography fontSize={isMobile ? '0.6rem' : '0.8rem'}>Renovação da assinatura:</Typography>
        <Typography
          fontSize={isMobile ? '0.6rem' : '0.8rem'}
          sx={{ fontWeight: status == 'paused' ? 800 : 300 }}>
          {status == 'paused' ? 'SUSPENSA' : next_billing_cycle}
        </Typography>
      </Box>
      {proximaFatura != valor && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'grey'
            }}>
            <Typography sx={{ mr: 1 }} fontSize={isMobile ? '0.6rem' : '0.8rem'}>
              Valor adicional:
            </Typography>
            <Typography fontSize={isMobile ? '0.6rem' : '0.8rem'}>
              *{' '}
              {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                (proximaFatura - valor * quantity) / 100
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end'
            }}>
            <Typography sx={{ mr: 1, color: 'grey' }} fontSize={isMobile ? '0.5rem' : '0.7rem'}>
              * Referente à adição de usuário
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}
