import { Avatar, Box, Button, Typography } from '@mui/material'
import Base from '../../layout/Base'
import logoSmall from '../../assets/logo-small.png'
import { useNavigate } from 'react-router-dom'

export const PasswordResetSuccess = () => {
  const navigate = useNavigate()
  return (
    <Base>
      <Box
        sx={{
          maxWidth: '368px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}>
        <Avatar src={logoSmall} />
        <Typography sx={{ mt: 2, fontSize: '1.5625rem', fontWeight: 700 }}>
          Sua senha foi alterada com sucesso
        </Typography>
        <Typography sx={{ mb: 2, fontSize: '0.875rem', fontWeight: 400 }}>
          Faça login com sua nova senha
        </Typography>
        <Button
          variant="contained"
          sx={{ mb: 2, width: '100%' }}
          onClick={() => {
            navigate('/')
          }}>
          Login
        </Button>
      </Box>
    </Base>
  )
}
