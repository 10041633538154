import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Box, Button, Chip, IconButton, Typography, useMediaQuery } from '@mui/material'
import { TAluno } from '../../types/aluno'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { UserForm } from './UserForm'
import { AuthContext } from '../../contexts/auth/AuthContext'
import moment from 'moment'

export const Users = (
  props: React.PropsWithChildren<{
    alunos: [TAluno]
    responsavel_aluno: string
  }>
) => {
  const { reloadUser, user } = useContext(AuthContext)

  const isMobile = useMediaQuery('(max-width: 600px)')
  const [mode, setMode] = useState<'create' | 'edit'>('create')
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false)
  const [alunoId, setAlunoId] = useState<string | undefined>(undefined)
  interface UserListProps {
    name: string
    situacao: string
    usuario_situacao: string
    data_limite_de_uso: string | undefined
    avatar?: string
    _id: string
  }

  const UserList = (props: UserListProps) => {
    var situacao = ''
    var color = ''
    var backgroundColor = ''

    if (
      (props.situacao == 'ATIVO' && props.usuario_situacao != 'ATIVO') ||
      props.usuario_situacao == 'RASCUNHO'
    ) {
      situacao = 'Não acessou'
      color = 'rgba(0, 0, 0, 0.54)'
      backgroundColor = 'rgba(0, 0, 0, 0.27)'
    } else if (props.usuario_situacao == 'ATIVO') {
      situacao = 'ATIVO'
      color = '#1B5E20'
      backgroundColor = '#1b5e1f57'
    } else if (props.situacao == 'CANCELADO') {
      situacao = 'CANCELADO'
      color = '#E65100'
      backgroundColor = '#e651003c'
    } else {
      situacao = 'Não acessou'
      color = 'rgba(0, 0, 0, 0.54)'
      backgroundColor = 'rgba(0, 0, 0, 0.27)'
    }

    return (
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{ mb: 1, cursor: 'pointer' }}
        justifyContent={'space-between'}
        gap={0}
        onClick={() => {
          setMode('edit')
          setAlunoId(props._id)
          setIsNewUserModalOpen(true)
        }}>
        <Avatar
          sizes="small"
          sx={{ marginRight: '5px', width: '34px', height: '34px', fontSize: '0.8rem' }}
          src={props.avatar ? props.avatar : undefined}>
          {props.name?.charAt(0)?.toUpperCase()}
        </Avatar>
        <Typography sx={{ fontSize: '0.8rem', fontWeight: 400 }}>
          {/* maximo de 20 caracteres no nome elipsis no final */}
          {props.name?.length > 20
            ? props.name?.substring(0, 15) + '...'
            : props.name?.substring(0, 15)}
        </Typography>
        <IconButton
          size="small"
          sx={{
            color: '#9C27B0',
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 700,
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#9C27B0'
            }
          }}>
          {props.data_limite_de_uso && props.usuario_situacao != 'INATIVO' && (
            <AccessTimeIcon sx={{ fontSize: '1.5rem', color: '#FF9800' }} />
          )}
        </IconButton>
        <Chip
          label={
            situacao.toString().slice(0, 1).toUpperCase() + situacao.slice(1).toLocaleLowerCase()
          }
          size="small"
          sx={{
            marginLeft: 'auto',
            cursor: 'pointer',
            backgroundColor,
            color,
            fontSize: '0.675rem'
          }}
        />

        <IconButton
          size="small"
          onClick={() => {
            setMode('edit')
            setAlunoId(props._id)
            setIsNewUserModalOpen(true)
          }}
          sx={{
            color: '#1976D2',
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 700,
            ml: '5px',
            mr: '-5px'
          }}>
          <EditIcon />
        </IconButton>
      </Box>
    )
  }

  useEffect(() => {
    reloadUser(props.responsavel_aluno)
  }, [isNewUserModalOpen])

  return (
    <Box
      sx={{
        minWidth: '200px',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        border: '1px solid rgba(0, 0, 0, 0.23)'
      }}>
      <Box
        id="scroll"
        sx={{ maxHeight: '250px', overflowY: 'auto', px: 2, my: 1, position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid rgba(0, 0, 0, 0.23)'
          }}>
          <Typography fontSize={isMobile ? '0.675rem' : '0.9rem'} fontWeight={700}>
            Usuários
          </Typography>
          <Button
            variant="text"
            endIcon={<AddIcon />}
            onClick={() => {
              const aluno = props.alunos?.find((a) => a.email === undefined)
              setMode(aluno?._id ? 'edit' : 'create')
              setAlunoId(aluno?._id)
              setIsNewUserModalOpen(true)
            }}
            sx={{
              color: '#1976D2',
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 700
            }}>
            {isMobile ? '  ' : 'Adicionar'}
          </Button>
        </Box>
        {props.alunos &&
          props.alunos.map(
            (aluno, idx) =>
              aluno.email && (
                <UserList
                  key={idx}
                  name={aluno.nome}
                  _id={aluno._id}
                  data_limite_de_uso={aluno.data_limite_de_uso}
                  situacao={aluno.situacao}
                  usuario_situacao={aluno.usuario_situacao}
                />
              )
          )}
        {isNewUserModalOpen && (
          <UserForm
            isOpen={isNewUserModalOpen}
            setIsOpen={setIsNewUserModalOpen}
            mode={mode}
            aluno={props.alunos.find((a) => a._id === alunoId) as TAluno | undefined}
            responsavel_aluno={props.responsavel_aluno}
          />
        )}
      </Box>
      {props.alunos?.find((a) => a.data_limite_de_uso) && (
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px',
            my: '1rem'
          }}>
          <AccessTimeIcon sx={{ fontSize: '1.5rem', color: '#FF9800' }} />
          <Typography sx={{ color: '#FF9800', fontSize: '0.8rem', fontWeight: 700 }}>
            Data limite de uso:{' '}
            {moment(props.alunos?.find((a) => a.data_limite_de_uso)?.data_limite_de_uso).format(
              'DD/MM/YYYY'
            )}
          </Typography>
        </Box>
      )}
      {isNewUserModalOpen && (
        <UserForm
          isOpen={isNewUserModalOpen}
          setIsOpen={setIsNewUserModalOpen}
          mode={mode}
          aluno={props.alunos.find((a) => a._id === alunoId) as TAluno | undefined}
          responsavel_aluno={props.responsavel_aluno}
        />
      )}
    </Box>
  )
}
