import { Avatar, Box, Typography } from '@mui/material'
import planner1 from '../../assets/images/planner1.png'
import planner2 from '../../assets/images/planner2.png'
import avatarDulce from '../../assets/images/dulce-logo.png'
import bottomBg from '../../assets/images/bottom.png'
import { useState } from 'react'
import { ExpandMore } from '@mui/icons-material'
import { useMutation } from 'react-query'

export const TermsOfUse = () => {
  const [expanded, setExpanded] = useState(false)

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh'
      }}>
      <Box>
        <Box position={'absolute'} top={0} left={0}>
          <img src={planner1} alt="planner" width={320} />
        </Box>
        <Box position={'absolute'} top={0} right={0}>
          <img src={planner2} alt="planner" width={150} />
        </Box>
      </Box>
      <Box sx={{ padding: '0 15% 150px 15%', mt: 10 }}>
        <Avatar sx={{ width: 64, height: 64, margin: 'auto' }}>
          <img src={avatarDulce} alt="avatar" width={64} />
        </Avatar>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.5rem',
            textAlign: 'center',
            mt: 2
          }}>
          TERMOS DE USO DA DULCE SAÚDE
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          Os presentes Termos e Condições regem toda a utilização dos serviços oferecidos pela{' '}
          <span style={{ color: 'purple' }}>HSPW SAÚDE PREVENTIVA LTDA</span> (“
          <span style={{ color: 'purple' }}> HSPW</span>”), através da plataforma{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>. Nossos serviços são oferecidos e
          sujeitos à sua aceitação, então, por favor, leia estes termos com atenção antes de acessar
          ou usar nossos Serviços.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          <span style={{ color: 'purple' }}>
            &quot;Olhar para as pessoas, cuidar do futuro&quot;
          </span>{' '}
          esse é o lema que move a <span style={{ color: 'purple' }}>Dulce Saúde</span>, uma
          plataforma que acredita que cuidar da saúde integral dos alunos tem impacto direto na
          felicidade, na formação do futuro adulto e na comunidade como um todo.{' '}
          <span style={{ color: 'purple' }}>Prevenir é melhor que remediar</span>, antes de tratar
          da doença é melhor tratar da saúde.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          A <span style={{ color: 'purple' }}>Dulce Saúde</span> é um serviço utilizado por várias
          escolas que buscam cuidar da saúde de seus alunos através da identificação,
          compartilhamento de informações assertivas e principalmente do ACOLHIMENTO. Toda nossa
          plataforma foi construída por médicos, psicólogos, nutricionistas, educadores físicos,
          assistentes sociais e um time completo multidisciplinar que garante a identificação e
          orientação para jovens de 12 a 18 anos nas mais diversas condições de saúde e bem estar.
        </Typography>

        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '1.5rem',
            mt: 2,
            textAlign: 'center'
          }}>
          O QUE MONITORAMOS
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          <span style={{ color: 'purple' }}>Saúde Psicológica</span>: Distúrbios psicológicos como
          Ansiedade, Depressão, Estresse, Autoestima, dentre diversos outros transtornos que se não
          tratados na raiz podem causar problemas graves para os indivíduos e a sociedade.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          <span style={{ color: 'purple' }}>Saúde Física</span>: Problemas relacionados à Saúde
          Física como Obesidade, Sedentarismo, Insônia, TDAH e outros potenciais agravantes.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          <span style={{ color: 'purple' }}>Comportamento e hábitos diários</span>: Vícios em
          Internet e redes sociais, ambiente familiar, dislexia, padrão de aprendizagem e outros
          itens que interferem diretamente na formação do jovem e no desempenho acadêmico .
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          A <span style={{ color: 'purple' }}>Dulce Saúde</span> traz benefícios para todas as
          partes envolvidas na relação com adolescentes, isto é, através dos cuidados e benefícios
          oferecidos às crianças e adolescentes, as famílias e escolas ganham com um ambiente mais
          saudável.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          Se você chegou até aqui é porque respeita e se preocupa com a saúde das crianças e
          adolescentes da sua vida, assim como nós nos preocupamos com o seu bem-estar e a sua
          privacidade. Por isso, solicitamos que leia atentamente os termos estipulados abaixo.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          Lembramos que:
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          ● A <span style={{ color: 'purple' }}>HSPW</span>, startup lançada em 2020, é uma
          plataforma Health Tech de identificação e acompanhamento contínuo da saúde integral de
          todos os seus usuários. Em 2022, diante dos resultados obtidos, lançou um produto
          específico e focado em jovens e adolescentes, que passam por um momento turbulento na
          vida.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          ● A <span style={{ color: 'purple' }}>DULCE SAÚDE</span> segue todas as práticas de ética
          e conduta da Psicologia, Serviço Social e demais leis necessárias para a disponibilização
          do seu serviço.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          ● Ao aceitar estes Termos de Uso você declara que possui os poderes necessários para
          firmar contratos, contrair obrigações e responder pelo Usuário, na qualidade de
          Responsável Legal deste.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          ● Identificação das partes:
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2, ml: 2 }}>
          ○ <span style={{ color: 'purple' }}>Dulce Saúde</span>: Plataforma de identificação e
          acompanhamento contínuo da saúde integral de crianças e adolescentes. A{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> oferece uma solução completa em saúde
          preventiva e curativa através de seu ecossistema único para crianças, adolescentes e seus
          familiares e/ou responsáveis.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2, ml: 2 }}>
          ○ ESCOLA(S): Independente se escrita no plural ou singular, trata-se de pessoa jurídica
          que atuará como CONTRATANTE da <span style={{ color: 'purple' }}>Dulce Saúde</span>,
          utilizando a plataforma para o gerenciamento da saúde integral de seus alunos.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2, ml: 2 }}>
          ○ RESPONSÁVEL LEGAL: Independente se escrito no plural ou singular, é a pessoa física
          responsável legal pelo menor de idade, que por meio da contratação direta ou por
          intermédio da Escola, manifeste anuência aos presentes Termos de Uso do sistema oferecido
          pela <span style={{ color: 'purple' }}>Dulce Saúde</span>.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2, ml: 2 }}>
          ○ USUÁRIOS: É a pessoa física da criança ou adolescente que utilize o sistema oferecido
          pela <span style={{ color: 'purple' }}>Dulce Saúde</span>, em decorrência da contratação
          desta por sua Escola ou seu Responsável Legal.
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '1rem',
            whiteSpace: 'pre-wrap',
            mt: 2
          }}>
          ● Os Usuários estão cientes, desde já, que para acessar os sistemas da{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> deverão reportar à Escola o interesse
          em utilizar a plataforma.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          O Responsável Legal que utiliza a plataforma tem total liberdade para realizar alterações
          cadastrais que contenham informações pessoais incorretas, tanto suas quanto dos Usuários,
          assim como, na ausência de manutenção por previsão legal, solicitar a exclusão das contas
          a qualquer momento.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          Os Termos e Condições de Uso constituem um Contrato firmado entre o Responsável Legal
          (&quot;Você&quot;) e a <span style={{ color: 'purple' }}>HSPW SAÚDE PREVENTIVA LTDA</span>
          (&quot;<span style={{ color: 'purple' }}>HSPW&quot;</span>), pessoa jurídica de direito
          privado estabelecida na Rua Menino Deus, nº 63, apartamento 102 C, bloco A, Centro, CEP
          88020-210, na cidade de Florianópolis, SC, inscrita no CNPJ sob nº 40.087.992/0001-50, com
          contrato social arquivado na Junta Comercial do Estado de Santa Catarina sob o NIRE nº
          42206389951.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1. FUNCIONAMENTO DA PLATAFORMA
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.1. Através de um “voucher” (convite) enviado para o e-mail do Usuário, após a anuência
          deste Termo, este poderá acessar a plataforma da{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> e complementar seu cadastro, criando
          um login e uma senha.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.2. Os Usuários poderão utilizar a plataforma{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> para participar dos ciclos de
          avaliações, responder às perguntas diárias fornecidas pelo sistema, acessar e monitorar
          seu diagnóstico, acessar sua análise de resultados individuais, acessar recomendações com
          base em diagnósticos realizados e editar respostas incompletas ou incorretas.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.3. Os pais e/ou responsáveis legais terão acesso a um Dashboard, interface visual que
          apresenta os dados de pagamento da plataforma, acesso aos Termos de Uso e Política de
          Privacidade da Plataforma e acesso aos dados do perfil dos Usuários cadastrados por eles.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.4. As informações dos Usuários não serão repassadas à Escola ou aos responsáveis legais,
          exceto nas hipóteses em que as informações divulgadas pelo Usuário da Plataforma,
          apresentem algum risco para a integridade física do Usuário ou de terceiros ou apresente
          risco à vida do Usuário ou de terceiros.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.5. A Escola terá acesso a um relatório de uso da Plataforma pelos Usuários cadastrados
          em sua base, como se observa:
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.5.1. Anônimo: A Escola receberá relatórios com informações estatísticas sobre a base de
          usuários, sem poder identificar individualmente um Usuário.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.5.2. Os Usuários são responsáveis pela segurança das informações de acesso à plataforma
          (login e senha), responsabilizando-se por jamais compartilhar tais informações, assim como
          com o dever de reportar ao suporte da <span style={{ color: 'purple' }}>Dulce Saúde</span>{' '}
          imediatamente em caso de esquecimento ou acesso indevido.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.5.3. Atendimento Psicológico: Na hipótese da Escola fornecer atendimento psicológico aos
          alunos, a <span style={{ color: 'purple' }}>Dulce Saúde</span> se reserva o direito de
          orientar os Usuários a procurarem ajuda psicológica.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          1.6. Os relatórios e diagnósticos fornecidos pela{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> serão, única e exclusivamente,
          elaborados com base nas informações fornecidas pelos Usuários.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2. TRATAMENTO DE DADOS PESSOAIS
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.1. A <span style={{ color: 'purple' }}>Dulce Saúde</span>, em atenção ao art. 14, §1º,
          da Lei Geral de Proteção de Dados Pessoais (LGPD), coletará, através das especificações
          contidas neste termo, o consentimento específico e em destaque de ao menos um dos
          representantes legais do Usuário.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.1.1. Os dados pessoais tratados na presente relação serão realizados visando o melhor
          interesse da criança e do adolescente.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.2. Para o cumprimento dos serviços que se propõe, a{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> precisará coletar e tratar
          informações pessoais dos Usuários, as quais estão detalhadas em nossa política de
          privacidade.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.3. A <span style={{ color: 'purple' }}>Dulce Saúde</span>, em casos de coleta de
          informações que ameacem a vida da pessoa ou de terceiros, poderá comunicar os responsáveis
          legais e/ou a escola, para que sejam tomadas as medidas necessárias para cessar essa
          situação, em respeito aos artigos 70 e 98, do Estatuto da Criança e do Adolescente.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.4. A <span style={{ color: 'purple' }}>Dulce Saúde</span> necessita das informações
          acima para poder prestar o serviço e suporte mais adequado aos Usuários, Escolas e
          Responsáveis Legais, assim como para garantir a segurança e a transparência com total
          respeito aos preceitos trazidos pela Lei Geral de Proteção de Dados Pessoais (LGPD) e
          demais disposições legais.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.4.1. É de total e exclusiva responsabilidade dos pais e representantes legais manterem
          as suas informações atualizadas junto à{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.4.2. Qualquer aviso, recomendação, atualização dos dados de pagamento, atualização dos
          Termos de Uso e/ou Política de Privacidade, será considerado lido e consentido pelos pais
          ou representantes legais, exceto se houver manifestação em contrário.
        </Typography>

        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.5. A <span style={{ color: 'purple' }}>Dulce Saúde</span> poderá utilizar os dados
          referentes ao histórico de saúde e características físicas do Usuário para identificar
          padrões e alterações em sua saúde física e mental, assim como elaborar e fornecer
          relatórios, estudos de casos, e outros materiais de caráter científico e/ou estatístico
          para uso próprio da <span style={{ color: 'purple' }}>Dulce Saúde</span> ou de terceiros,
          desde que respeitada a anonimização dos dados pessoais.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.6. São concedidos aos Usuários todos os direitos sobre as suas informações pessoais, de
          forma que este deverá entrar em contato com o departamento da Escola contratante ou
          diretamente com o canal de suporte da <span style={{ color: 'purple' }}>Dulce Saúde</span>{' '}
          para discutir tais questões.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.7. A <span style={{ color: 'purple' }}>Dulce Saúde</span> empregará seus melhores
          esforços para respeitar e proteger os dados e as informações pessoais contra perda, roubo
          ou qualquer modalidade de uso indevido, bem como contra acesso não autorizado, divulgação,
          alteração e destruição.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.8. O Usuário, através do presente instrumento e da anuência por parte de seu
          Representante Legal aqui manifestada, autoriza a utilização gratuita de sua imagem, caso
          opte pela funcionalidade stories da plataforma.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.8.1. Através desta funcionalidade, o Usuário poderá inserir imagens em seu perfil na
          plataforma, que estarão disponíveis à outros Usuários e à Escola.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          2.8.2. A publicação realizada pelo Usuário, passará por um curadoria da Dulce Saúde,
          apenas após a aprovação da Dulce Saúde que o stories ficará visível para outros Usuários.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          3. RESPONSABILIDADES E DIREITOS DOS USUÁRIOS
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          3.1. Os Usuários da plataforma <span style={{ color: 'purple' }}>Dulce Saúde</span> se
          comprometem a (i) não compartilhar informações falsas; (ii) não divulgar para terceiros
          informações obtidas através da plataforma sem antes obter autorização da{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>; (iii) conceder permissão para a
          Dulce Saúde atualizar os dados obtidos no software, seja em computadores, smartphones,
          tablets ou outros dispositivos correlatos; (iv) respeitar integralmente os padrões de
          conduta e os termos de uso da plataforma em suas comunicações dentro do software,
          observando todas as disposições destes Termos, bem como a legislação brasileira.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          3.2. Ao aceitar estes Termos o Usuário e seu Representante Legal reconhecem que leram,
          entenderam e concordaram em cumprir os presentes termos.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4. LIMITES DE RESPONSABILIDADE
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.1. Cada Usuário é o único e exclusivo responsável por toda e qualquer informação
          adicionada na plataforma. Em nenhuma hipótese a{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> se responsabiliza pelas informações,
          valores, dados ou coleta de informações adicionados por quaisquer dos Usuários.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.2. Os Responsáveis Legais que reconhecem expressamente sua responsabilidade pessoal,
          assumindo os riscos cíveis, administrativos e criminais pelas condutas e informações
          inseridas pelos Usuários na plataforma.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.3. A <span style={{ color: 'purple' }}>Dulce Saúde</span> irá emitir relatórios às
          Escolas com base nas informações repassadas pelos Usuários, sem qualquer interpretação ou
          julgamento de valor.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.3.1. A <span style={{ color: 'purple' }}>Dulce Saúde</span> também poderá, caso queira o
          Responsável Legal, ser utilizada pelos familiares do Usuário. Os relatórios dos Usuários
          dos familiares ficarão disponíveis de forma identificada apenas para estes, enquanto que,
          para a Escola, poderão ser repassados única e exclusivamente de forma anonimizada.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.4. A <span style={{ color: 'purple' }}>Dulce Saúde</span>, A Dulce Saúde, através do RH
          da Escola, oferece suporte técnico integral aos Usuários da plataforma.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.5. A <span style={{ color: 'purple' }}>Dulce Saúde</span> não se responsabiliza pela
          utilização, tratamento, ações, ou qualquer outra atitude tomada pela Escola em relação aos
          relatórios emitidos.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.6. Ainda que a <span style={{ color: 'purple' }}>Dulce Saúde</span> possa indicar e
          recomendar práticas para tratamentos físicos e/ou mentais, esta não se responsabiliza pelo
          resultado definitivo, como também pelo agravamento de possíveis enfermidades, distúrbios,
          limitações, ou qualquer outro diagnóstico médico constatado ou não pela plataforma.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.7. A <span style={{ color: 'purple' }}>Dulce Saúde</span> não se responsabiliza por
          perdas e/ou prejuízos causados por interrupções na prestação do serviço causadas por
          motivos fortuitos, como desastres naturais, acidentes, incêndios, enchentes.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.8. A <span style={{ color: 'purple' }}>Dulce Saúde</span> não assegura que o serviço
          será ininterrupto ou livre de falhas. No entanto, se a plataforma não estiver disponível
          devido à manutenção planejada, procuraremos notificar todos os Usuários com pelo menos 5
          (cinco) dias de antecedência.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.9. A <span style={{ color: 'purple' }}>Dulce Saúde</span> possui relação exclusivamente
          contratual com a Escola, não fazendo parte desta e nem possuindo qualquer vínculo senão o
          de mera prestação de serviços. O serviço prestado, sob nenhuma hipótese, estabelece
          vínculo empregatício ou de representação entre as partes, de forma que a escola é
          totalmente responsável por suas próprias obrigações ( tributárias, trabalhistas e
          previdenciárias), não podendo, em hipótese nenhuma, recair tais obrigações sobre a{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          4.10. O uso da plataforma <span style={{ color: 'purple' }}>Dulce Saúde</span> não cria
          qualquer relação de consumo entre esta e o Usuário na execução do contrato, sendo que este
          último terá relação de consumo única e exclusivamente com a Escola.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          5. PROPRIEDADE INTELECTUAL
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          5.1. Toda a identidade visual disponibilizada na plataforma, exceto por aquela advinda das
          escolas, é de propriedade única e exclusiva da{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span> assim como todos os softwares
          utilizados, marca, patentes, segredos comerciais e direitos autorais.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          5.2. Os Usuários se comprometem a não fazer qualquer tipo de uso da marca, identidade
          visual, conteúdos e materiais elaborados e disponibilizados pela{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>, ou qualquer outro tipo de
          propriedade intelectual do sistema sem que haja o prévio e expresso consentimento da
          plataforma, bem como se comprometem a não reproduzir, modificar, traduzir, adaptar, fazer
          engenharia reversa, desmontar ou criar derivações dos softwares ou da plataforma.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          6. SUSPENSÃO OU CANCELAMENTO DO CADASTRO
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          6.1. Havendo infrações ao disposto nos termos de uso da{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>, bem como à legislação, o Usuário
          infrator poderá ter seu cadastro suspenso ou cancelado, a depender da gravidade da
          infração.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          6.2. Em caso de cancelamento da conta do Usuário, a{' '}
          <span style={{ color: 'purple' }}>HSPW</span> notificará o Usuário infrator e a Escola,
          com pelo menos 5 (cinco) dias de antecedência à aplicação da penalidade. No período
          compreendido entre a notificação e a efetivação da sanção, o Usuário terá sua conta
          bloqueada na Plataforma <span style={{ color: 'purple' }}>Dulce Saúde</span> e será
          possibilitado a apresentação de justificativas sobre os atos tidos como ilegais, depois
          disso a <span style={{ color: 'purple' }}>Dulce Saúde</span> analisará a justificativa e
          definirá a sanção a ser aplicada ou desbloqueio da conta.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          6.3. O bloqueio do Usuário na plataforma, em nada altera o uso desta por parte da Escola,
          desde que não seja comprovado a ligação da Escola com o respectivo ato ilegal.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          6.4. O cancelamento do cadastro por parte do Usuário deverá ser requerido ao RH da Escola.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          7. REGISTROS E RELAÇÕES
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          7.1. Os pais ou representantes legais dos Usuários são os únicos juridicamente habilitados
          para aceitar os presentes Termos de Uso para que os seus dependentes possam utilizar a
          Plataforma.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          7.2. O pagamento da licença de uso do Usuário poderá ser realizado tanto pelos pais ou
          responsáveis, quanto pela Escola.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          7.3. Na hipótese do cadastro do Usuário ser realizado pela Escola, essa incluirá as
          informações básicas do perfil do aluno e enviará um e-mail para os pais ou responsáveis
          legais do aluno aceitar os Termos de Uso da Plataforma. Será de responsabilidade da Escola
          comunicar aos pais ou responsáveis legais as informações relevantes da relação jurídica
          tais quais, mas não se limitando a: finalidade, funcionalidades e responsabilidades, bem
          como, o contido nos presentes Termos de Uso.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          7.3.1. Cabe à Escola, solicitar à <span style={{ color: 'purple' }}>Dulce Saúde</span>, a
          alteração, limitação, e/ou exclusão de determinado Usuário, com aviso prévio de 5 dias
          úteis (cinco dias úteis).
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>8. PROIBIÇÕES</Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          8.1. É expressamente vedado ao Usuário:
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          8.1.1. Licenciar, vender, alugar, arrendar, transferir, ceder, distribuir, hospedar ou, de
          outra forma, explorar comercialmente o serviço da{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>, seja no todo ou em parte, ou
          qualquer conteúdo exibido.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          8.1.2. Modificar, fazer trabalhos derivados, desmontar, compilar reversamente ou fazer
          engenharia reversa de qualquer parte da plataforma{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          8.1.3. Acessar a plataforma <span style={{ color: 'purple' }}>Dulce Saúde</span> com
          intenção de construir um site, produto ou serviço similar ou competitivo.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          8.1.4. Disponibilizar as informações obtidas através da plataforma a terceiros, sem a
          devida autorização pela <span style={{ color: 'purple' }}>Dulce Saúde</span>.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          9. ATUALIZAÇÃO E VIGÊNCIA DOS TERMOS DE USO
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          9.1. A <span style={{ color: 'purple' }}>Dulce Saúde</span> busca sempre aprimorar sua
          plataforma e os serviços oferecidos, de forma que, eventualmente, estes Termos de Uso e a
          nossa Política de Privacidade poderão necessitar de atualizações. As referidas
          alterações/atualizações serão sempre notificadas aos Usuários, pais ou responsáveis legais
          e à Escola através do e-mail cadastrado na Plataforma{' '}
          <span style={{ color: 'purple' }}>Dulce Saúde</span>.{' '}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          9.2. O uso continuado de nosso serviço, após a notificação de tais mudanças, indicará seu
          reconhecimento e concordância em ficar vinculado aos termos e condições das mesmas.{' '}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          9.3. Todos os termos e políticas <span style={{ color: 'purple' }}>Dulce Saúde</span>{' '}
          passam a vigorar a partir do momento em que os Pais ou Responsáveis Legais se cadastrem na
          plataforma e aderem a eles.
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          9.4. Sua vigência será por prazo indeterminado, vigorando enquanto durar a relação
          jurídica entre a <span style={{ color: 'purple' }}>Dulce Saúde</span> e a Escola ou
          Responsável Legal, a depender do responsável pela contratação da plataforma, estendendo-se
          a todos os Usuários que fazem uso da plataforma em decorrência desta relação.{' '}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          9.5. Aplica-se o presente Termo aos Usuários que não são o menor de idade, no que couber.{' '}
        </Typography>
        <Typography sx={{ fontWeight: 400, fontSize: '1rem', mt: 2 }}>
          9.6. Aplica-se para o presente Termo as disposições do Código Civil referentes à
          responsabilidade civil de absolutamente incapazes e relativamente incapazes.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${bottomBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '10vw',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 99
        }}
      />
    </Box>
  )
}

export default TermsOfUse
