import { Avatar, Box, Button, TextareaAutosize, Typography } from '@mui/material'
import Base from '../../layout/Base'
import logoSad from '../../assets/logo-sad.png'
import LogoutIcon from '@mui/icons-material/Logout'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'

export const CancelSubscriptionFeedback = () => {
  const { signOut } = useContext(AuthContext)
  return (
    <Base>
      <Avatar src={logoSad} sx={{ width: '64px', height: '64px', margin: '0 auto' }} />
      <Typography fontSize={'1.5rem'} fontWeight={700} mt={2} textAlign={'center'}>
        Vamos sentir sua falta
      </Typography>
      <Typography fontSize={'1rem'} fontWeight={400} maxWidth={'400px'} textAlign={'center'} mt={2}>
        Obrigado pelo tempo que passamos juntos, estaremos te esperando se quiser retornar no futuro
      </Typography>
      <TextareaAutosize
        minRows={5}
        placeholder="Deixe um feedback sobre sua experiência com o Dulce (opcional) ..."
        style={{
          width: '100%',
          maxWidth: '346px',
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #0000001A',
          resize: 'none',
          marginTop: '32px'
        }}
      />
      <Button variant={'contained'} sx={{ width: '100%', maxWidth: '380px', mt: 4 }}>
        Enviar feedback
      </Button>
      <Button variant={'text'} sx={{ width: '100%', maxWidth: '380px', mt: 2 }} onClick={signOut}>
        <LogoutIcon sx={{ mr: 1 }} /> Sair
      </Button>
    </Base>
  )
}
