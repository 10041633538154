import { Box } from '@mui/material'
import React from 'react'

export const BaseDashboard = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        position: 'relative'
      }}>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '50%',
          backgroundColor: '#BDC5EA',
          borderRadius: '0 0 40px 40px',
          zIndex: '-1'
        }}
      />
      {children}
    </Box>
  )
}
