import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import DoneIcon from '@mui/icons-material/Done'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import MaskedInput from './MaskedInput'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import alunoServices from '../../services/alunoServices'
import { useMutation } from 'react-query'
import { enqueueSnackbar } from 'notistack'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
import { da } from 'date-fns/locale'
import { set } from 'date-fns'

const schema = z.object({
  name: z.string().nonempty({ message: 'Nome é obrigatório' }),
  birthDate: z.string().nonempty({ message: 'Data de nascimento é obrigatório' }),
  email: z.string().email('E-mail inválido').min(5),
  phone: z.string().nonempty({ message: 'Telefone é obrigatório' })
})
const PendingStatus = () => {
  return (
    <Chip
      label="Pendente"
      icon={<ErrorOutlineIcon color="warning" />}
      sx={{
        ml: 'auto',
        color: 'rgba(237, 108, 2, 1)',
        backgroundColor: 'white',
        cursor: 'pointer'
      }}
    />
  )
}

const SavedStatus = () => {
  return (
    <Chip
      label="Salvo"
      icon={<DoneIcon color="success" />}
      sx={{
        ml: 'auto',
        color: 'rgba(0, 128, 0, 1)',
        backgroundColor: 'white',
        cursor: 'pointer'
      }}
    />
  )
}

interface UserAccordionProps {
  aluno: any
  index: number
  total: number
}

export const UserAccordion = ({ aluno, index, total }: UserAccordionProps) => {
  const { user, reloadUser } = useContext(AuthContext)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  type Schema = z.infer<typeof schema>

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const phoneRef = useRef(null)

  const handleBirthDateChange: any = (value: any) => {
    const datePtBr = dayjs(value).format('DD/MM/YYYY')
    setValue('birthDate', datePtBr)
    setSelectedDate(dayjs(value))
  }

  const updateAlunoMutation = useMutation(
    (payload: any) => alunoServices.updateAluno(payload, payload.responsavel_aluno),
    {
      onSuccess: (data) => {
        reloadUser(user._id)
        //accordion close
        setIsAccordionOpen(false)

        enqueueSnackbar('Usuário atualizado com sucesso', { variant: 'success' })
      },
      onError: (error) => {
        enqueueSnackbar('Erro ao atualizar usuário', { variant: 'error' })
      }
    }
  )

  const onSubmit = handleSubmit((data) => {
    const payload = {
      _id: aluno._id,
      nome: data.name,
      telefone: data.phone.replace(/\D/g, ''),
      email: data.email,
      data_nascimento: data.birthDate,
      responsavel_aluno: user._id
    }

    //verifica se a idade for menor que 12 anos
    const birthDate = moment(data.birthDate, 'DD/MM/YYYY')
    const today = moment()
    const age = today.diff(birthDate, 'years')

    if (age < 12) {
      setError('birthDate', {
        type: 'manual',
        message: 'Idade deve ser maior que 12 anos'
      })
      return
    }

    updateAlunoMutation.mutate(payload)
  })

  const date = dayjs(aluno?.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    aluno?.data_nascimento ? dayjs(date) : null
  )

  const [tel, setTel] = useState<string | null>(aluno?.telefone ? aluno?.telefone : null)

  useLayoutEffect(() => {
    setValue('name', aluno.nome)
    setValue('phone', aluno.telefone)
    setValue('email', aluno.email)
    setValue('birthDate', aluno.data_nascimento)
  }, [])

  const email = !!aluno.email

  return (
    <Box sx={{ width: '100%' }}>
      <Accordion
        sx={{ border: '1px solid rgba(0, 0, 0, 0.23)', width: '100%' }}
        expanded={isAccordionOpen}
        onChange={() => setIsAccordionOpen(!isAccordionOpen)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '0.875rem',
              lineHeight: '1.1875rem',
              color: 'rgba(0, 0, 0, 0.87)',
              mt: '0.5rem'
            }}>
            {`Usuário ${index} de ${total}`}
          </Typography>
          {!email ? <PendingStatus /> : <SavedStatus />}
        </AccordionSummary>
        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <form
            onSubmit={onSubmit}
            style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <TextField
              label="Nome completo"
              variant="filled"
              autoComplete="off"
              InputProps={{
                sx: { backgroundColor: 'white' }
              }}
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            {/* Data de nascimento */}
            <FormControl variant="filled" sx={{ width: '100%' }}>
              <DatePicker
                label="Data de nascimento"
                onChange={handleBirthDateChange}
                value={selectedDate || null}
                slotProps={{
                  textField: {
                    variant: 'filled',
                    InputProps: {
                      sx: { backgroundColor: 'white' }
                    }
                  }
                }}
              />
              <FormHelperText error={!!errors.birthDate}>
                {errors.birthDate?.message}
              </FormHelperText>
            </FormControl>
            <TextField
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
              label="E-mail"
              variant="filled"
              autoComplete="off"
              InputProps={{
                sx: { backgroundColor: 'white' }
              }}
            />

            {/* telefone */}
            <TextField
              {...register('phone')}
              error={!!errors.phone}
              helperText={errors.phone?.message}
              label="Telefone"
              variant="filled"
              autoComplete="off"
              type="text"
              onChange={(e) => setTel(e.target.value)}
              value={tel || ''}
              InputProps={{
                // value: getValues('phone') || '',
                inputComponent: MaskedInput as any,
                inputProps: {
                  mask: '(99) 99999-9999',
                  inputRef: phoneRef,
                  sx: { backgroundColor: 'white' }
                }
              }}
            />

            <Button variant="contained" type="submit" sx={{ mt: '1rem' }}>
              {updateAlunoMutation.isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Salvar'
              )}
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
