import api from './api'

export interface ICouponServices {
  verifyCoupon: (data: any) => Promise<any>
}

export const couponServices = () => ({
  verifyCoupon: async (cupom: String) => {
    try {
      const { data: response } = await api.get('/dulce/b2c/verifica-cupom', { params: { cupom } })
      return response
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export default <ICouponServices>couponServices()
