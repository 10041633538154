import axios, { AxiosInstance } from 'axios'
import { enqueueSnackbar } from 'notistack'
import process from 'process'

const axiosService: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('@token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

//token expired
axiosService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 499) {
      localStorage.removeItem('@token')
      window.location.href = '/'
    }

    if (error.response.status === 500) {
      console.log(error.response)
      enqueueSnackbar('Erro interno no servidor', { variant: 'error' })
    }

    return Promise.reject(error)
  }
)

// axiosService.interceptors.response.use(
//   (response) => {
//     console.log(`Intercepted response from ${response.config.url}`)
//     return response
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

export default axiosService
