import { Routes, Route } from 'react-router-dom'
import { Dashboard } from '../pages/Dashboard'
import { TermsOfUse } from '../pages/TermsOfUse'

import { Navigate } from 'react-router-dom'
import { CreateAccount } from '../pages/CreateAccount'
import { EmailValidation } from '../pages/EmailValidation'
import { ChoosePlan } from '../pages/ChoosePlan'
import { PaymentData } from '../pages/PaymentData'
import { PaymentSucess } from '../pages/PaymentSucess'
import { Login } from '@mui/icons-material'
import { CancelSubscription } from '../pages/CancelSubscription'
import { CancelSubscriptionFeedback } from '../pages/CancelSubscriptionFeedback'
import { ParentInformation } from '../pages/ParentInformation'
import { PasswordSetNew } from '../pages/PasswordSetNew'
import { PasswordResetSuccess } from '../pages/PasswordResetSuccess'
import { PasswordReset } from '../pages/PasswordReset'
import { PasswordResetEmailSent } from '../pages/PasswordResetEmailSent'
import { CreateUserChild } from '../pages/CreateUserChild'

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />

      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="/email-validation" element={<EmailValidation />} />
      <Route path="/choose-plan" element={<ChoosePlan />} />
      <Route path="/payment-data" element={<PaymentData />} />
      <Route path="/payment-sucess" element={<PaymentSucess />} />
      <Route path="/parent-information" element={<ParentInformation />} />
      <Route path="/cancel-subscription" element={<CancelSubscription />} />
      <Route path="/cancel-subscription-feedback" element={<CancelSubscriptionFeedback />} />
      <Route path="/pr" element={<PasswordSetNew />} />
      <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/password-reset-email-sent" element={<PasswordResetEmailSent />} />
      <Route path="/create-user-child" element={<CreateUserChild />} />

      <Route path="*" element={<Login />} />
    </Routes>
  )
}
