import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import Base from '../../layout/Base'
import logoSmall from '../../assets/logo-small.png'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useMutation } from 'react-query'
import userServices from '../../services/userServices'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useState } from 'react'

const schema = z
  .object({
    password: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres').nonempty(),
    confirmPassword: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres').nonempty()
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não coincidem',
    path: ['confirmPassword']
  })

export const PasswordSetNew = () => {
  type Schema = z.infer<typeof schema>
  const queryParams = new URLSearchParams(location.search)
  const hash = queryParams.get('hash')
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const createAccountMutation = useMutation(
    ({ hash, nova_senha }: { hash: string; nova_senha: string }) => {
      return userServices.changePassword({ hash, nova_senha })
    },
    {
      onSuccess: () => {
        navigate('/password-reset-success')
      },
      onError: (error: any) => {
        enqueueSnackbar(error?.message || 'Erro ao alterar senha', { variant: 'error' })
      }
    }
  )

  const onSubmit = async (data: Schema) => {
    const payload = {
      hash: hash as string,
      nova_senha: data.password
    }
    createAccountMutation.mutate(payload)
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword)
  }

  return (
    <Base>
      <Box
        sx={{
          maxWidth: '368px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}>
        <Avatar src={logoSmall} />
        <Typography sx={{ mt: 2, fontSize: '1.5625rem', fontWeight: 700, mb: 2 }}>
          Escolha uma nova senha
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            label="Nova senha"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            sx={{ width: '100%' }}
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            onChange={(event) => {
              event.target.value = event.target.value.replace(/\s/g, '')
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {/* <Typography sx={{ mt: 2, mb: 2, fontSize: '12px', fontWeight: 400 }}>
            Nova senha Sua nova senha deve ter ao menos 8 caracteres, contendo letras maiúsculas e
            minúsculas, números e caracteres especiais (@#$%)
          </Typography> */}
          <TextField
            label="Confirme a nova senha"
            type={showConfirmPassword ? 'text' : 'password'}
            variant="outlined"
            sx={{ width: '100%', mt: 2 }}
            {...register('confirmPassword')}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            onChange={(event) => {
              event.target.value = event.target.value.replace(/\s/g, '')
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleToggleConfirmPasswordVisibility} edge="end">
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button variant="contained" sx={{ mt: 2, mb: 2, width: '100%' }} type="submit">
            {createAccountMutation.isLoading ? (
              <CircularProgress size={24} style={{ color: 'white' }} />
            ) : (
              'Salvar nova senha'
            )}
          </Button>
        </form>
      </Box>
    </Base>
  )
}
