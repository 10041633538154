import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Base from '../../layout/Base'
import logo from '../../assets/logo-small.png'
import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import userServices from '../../services/userServices'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import MaskedInput from '../../components/UserAccordion/MaskedInput'

const schema = z.object({
  name: z.string().nonempty({ message: 'Campo obrigatório' }),
  telefone: z.string().nonempty({ message: 'Campo obrigatório' }),
  parentesco: z.string().nonempty({ message: 'Campo obrigatório' }),
  uf: z.string().nonempty({ message: 'Campo obrigatório' }),
  cidade: z.string().nonempty({ message: 'Campo obrigatório' })
})

export const ParentInformation = () => {
  const { user, updateUserLocal } = useContext(AuthContext)

  const queryParams = new URLSearchParams(location.search)
  const responsavel_aluno = queryParams.get('responsavel_aluno')
  const cpf = queryParams.get('cpf')
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  type Schema = z.infer<typeof schema>
  const phoneRef = useRef(null)

  const [estados, setEstados] = useState<{ label: string; value: string }[]>([])
  const [estado, setEstado] = useState('')
  const [cidades, setCidades] = useState<{ label: string; value: string }[]>([])
  const [cidade, setCidade] = useState<{ label: string; value: string } | null>(null)
  const [relationship, setRelationship] = useState('')

  useEffect(() => {
    setCidades([])
    setCidade(null)
    setValue('cidade', '')
  }, [estado])

  const { data: _getAllEstados } = useQuery('uf', userServices.getEstados, {
    onSuccess: (data) => {
      setEstados([])
      if (Array.isArray(data.response)) {
        data?.response.forEach((estado: { nome: string; sigla: string }) => {
          setEstados((prevEstados) => {
            if (Array.isArray(prevEstados)) {
              return [...prevEstados, { label: estado.nome, value: estado.sigla }]
            }
            return prevEstados
          })
        })
      }
    },
    refetchOnWindowFocus: false
  })

  const { data: _getAllCidades } = useQuery(
    ['cidades', estado],
    () => userServices.getCidades(estado),
    {
      enabled: !!estado,
      onSuccess: (data) => {
        setCidades([])
        if (Array.isArray(data?.response)) {
          data?.response.forEach((cidade: { nome: string }) => {
            setCidades((prevCidades) => {
              if (Array.isArray(prevCidades)) {
                return [...prevCidades, { label: cidade.nome, value: cidade.nome }]
              }
              return prevCidades
            })
          })
        }
      }
    }
  )

  const handleRelationshipChange = (event: any) => {
    setRelationship(event.target.value as string)
  }

  const navigate = useNavigate()

  const {
    data: _updateUserData,
    mutate: updateUserMutation,
    isLoading: updateUserLoadinf
  } = useMutation(
    (data: Schema) => {
      const payload = {
        ...data,
        id: responsavel_aluno,
        uf: estado,
        nome: data.name
      }

      updateUserLocal({
        nome: data.name,
        telefone: data.telefone,
        parentesco: data.parentesco,
        uf: estado,
        cidade: data.cidade
      })
      return userServices.updateUser(payload)
    },
    {
      onSuccess: (data) => {
        navigate('/create-user-child')
      }
    }
  )

  const handleSubmitForm = (data: Schema) => {
    const payload = {
      ...data,
      telefone: data.telefone.replace(/\D/g, '')
    }
    console.log(payload)
    updateUserMutation(data)
  }

  return (
    <Base>
      <Avatar src={logo} sx={{ width: '59px', height: '59px' }} />
      <Typography sx={{ fontSize: '1.56rem', fontWeight: 700, textAlign: 'center' }}>
        Conclua seu cadastro
      </Typography>
      <Typography sx={{ fontSize: '0.875rem', textAlign: 'center' }}>
        Preencha os campos abaixo para continuar
      </Typography>

      <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
        <Box
          sx={{
            padding: '1rem'
          }}>
          <TextField
            label="Nome completo"
            required
            variant="outlined"
            fullWidth
            {...register('name')}
            helperText={errors.name?.message}
            error={!!errors.name}
          />
          <TextField
            label="Telefone"
            required
            variant="outlined"
            fullWidth
            {...register('telefone')}
            helperText={errors.telefone?.message}
            error={!!errors.telefone}
            sx={{ marginTop: '1rem' }}
            InputProps={{
              inputComponent: MaskedInput as any,
              inputProps: {
                mask: '(99) 99999-9999',
                inputRef: phoneRef,
                sx: { backgroundColor: 'white' }
              }
            }}
          />

          <FormControl fullWidth sx={{ marginTop: '1rem' }}>
            <InputLabel>Parentesco *</InputLabel>
            <Select
              labelId="parentesco-select-label"
              id="parentesco-select"
              value={relationship}
              label="Parentesco"
              {...register('parentesco')}
              error={!!errors.parentesco}
              onChange={handleRelationshipChange}>
              <MenuItem value="pai">Pai</MenuItem>
              <MenuItem value="mae">Mãe</MenuItem>
              <MenuItem value="avo">Avó</MenuItem>
              <MenuItem value="avo">Avô</MenuItem>
              <MenuItem value="tio">Tio</MenuItem>
              <MenuItem value="tia">Tia</MenuItem>
              <MenuItem value="irmao">Irmão</MenuItem>
              <MenuItem value="irma">Irmã</MenuItem>
              <MenuItem value="outro">Outro</MenuItem>
            </Select>
            {errors.parentesco && (
              <Box
                sx={{
                  color: 'red',
                  fontSize: '0.78rem',
                  marginTop: '0.25rem',
                  fontWeight: 700,
                  pl: '0.55rem'
                }}>
                {errors.parentesco.message}
              </Box>
            )}
          </FormControl>

          <Box sx={{ display: 'flex', gap: '0.5rem', marginTop: '1rem' }}>
            <Autocomplete
              disablePortal
              id="combo-box-estado"
              options={estados}
              sx={{ width: '100%' }}
              onChange={(_event, value) => {
                setEstado(value?.value || '')
              }}
              value={estados?.find((e) => e.value == estado ?? '') || null}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estado"
                  required
                  helperText={errors.uf?.message}
                  error={!!errors.uf}
                  {...register('uf')}
                />
              )}
            />
            {cidades && (
              <Autocomplete
                disablePortal
                id="combo-box-cidade"
                disabled={!estado}
                value={cidade}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(_event, value) => {
                  setCidade(value)
                }}
                options={cidades}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    required
                    helperText={errors.cidade?.message}
                    error={!!errors.cidade}
                    {...register('cidade')}
                  />
                )}
              />
            )}
          </Box>
          <Button variant="contained" sx={{ marginTop: '1rem', width: '100%' }} type="submit">
            {updateUserLoadinf ? <CircularProgress size={24} /> : 'Salvar'}
          </Button>
        </Box>
      </form>
    </Base>
  )
}
