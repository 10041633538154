import React, { forwardRef } from 'react'
import InputMask from 'react-input-mask'

const MaskedInput = forwardRef((props: any, ref: any) => {
  return <InputMask {...props} inputRef={ref} />
})

MaskedInput.displayName = 'MaskedInput'

export default MaskedInput
