import { createContext } from 'react'

export interface IAuthContext {
  user: any | null
  setUser: (user: any) => void
  setToken: (token: string) => void
  signIn: (email: string, password: string) => Promise<boolean>
  signOut: () => void
  signed: boolean
  updateUserLocal: (user: any) => void
  reloadUser: (usuario_responsavel: string) => void
  updatePaymentMethodLocal: (paymentMethod: any) => void
}

export const AuthContext = createContext({} as IAuthContext)
