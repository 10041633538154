import { Avatar, Box, Button, Typography } from '@mui/material'
import Base from '../../layout/Base'
import logoSmall from '../../assets/logo-small.png'
import { UserAccordion } from '../../components/UserAccordion'

import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'

import { useNavigate } from 'react-router-dom'

export const CreateUserChild = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const alunos = user?.alunos

  const isAllAlunosActive = alunos?.every((aluno: any) => !!aluno.email)

  return (
    <Base>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem',
          maxWidth: '350px',
          p: 2
        }}>
        <Avatar src={logoSmall} />
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, textAlign: 'center' }}>
          Cadastre os usuários do aplicativo
        </Typography>
        <Typography sx={{ fontSize: '0.875rem', textAlign: 'center' }}>
          Precisamos dessas informações para enviar o acesso ao app e contatar o usuário caso
          necessário
        </Typography>
        {alunos?.map((aluno: any, index: number) => {
          return (
            <UserAccordion key={aluno._id} index={index + 1} aluno={aluno} total={alunos.length} />
          )
        })}
        <Button
          variant="contained"
          fullWidth
          sx={{
            '&:disabled': {
              backgroundColor: '#e0e0e0'
            }
          }}
          onClick={() => {
            navigate('/dashboard')
          }}>
          {isAllAlunosActive ? 'Finalizar' : 'Cadastrar mais tarde'}
        </Button>
        <Box id="space" sx={{ mb: 10 }} />
      </Box>
    </Base>
  )
}
