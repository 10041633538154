import React, { useState, useEffect, useContext } from 'react'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Link
} from '@mui/material'

import { AuthContext } from '../../../contexts/auth/AuthContext'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
// import UploadIcon from '@mui/icons-material/Upload'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplayIcon from '@mui/icons-material/Replay'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import alunoServices from '../../../services/alunoServices'
import userServices from '../../../services/userServices'
import { TAluno } from '../../../types/aluno'
import moment from 'moment'
import { Label } from '@mui/icons-material'
import planServices from '../../../services/planServices'

const schema = z.object({
  nome: z.string().min(6, 'O nome deve ter no mínimo 6 caracteres'),
  email: z.string().email('E-mail inválido'),
  cidade: z.string().min(3, 'Selecione uma cidade'),
  uf: z.string().min(2, 'Selecione um estado'),
  telefone: z.string().nonempty('Obrigatório').min(11, 'Telefone inválido'),
  nome_escola: z.string().optional(),
  parentesco: z.string().optional(),
  data_nascimento: z
    .string()
    .or(z.date())
    .refine(
      (data) => {
        return moment(data).isBefore(moment().subtract(12, 'years'))
      },
      { message: 'A idade mínima são 12 anos' }
    )
})

type Schema = z.infer<typeof schema>

export const UserForm = (
  props: React.PropsWithChildren<{
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    mode: 'create' | 'edit'
    aluno: TAluno | undefined
    responsavel_aluno: string
  }>
) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      nome: props.aluno?.nome,
      email: props.aluno?.email,
      telefone: props.aluno?.telefone,
      nome_escola: props.aluno?.nome_escola,
      parentesco: props.aluno?.parentesco,
      data_nascimento: props.aluno?.data_nascimento
        ? moment(props.aluno?.data_nascimento).format('YYYY-MM-DD')
        : '',
      cidade: props.aluno?.cidade,
      uf: props.aluno?.uf
    }
  })
  const { user } = useContext(AuthContext)

  const isMobile = useMediaQuery('(max-width: 600px)')
  const [open, setOpen] = useState(true)
  const [estados, setEstados] = useState<{ label: string; value: string }[]>([])
  const [estado, setEstado] = useState('')
  const [cidades, setCidades] = useState<{ label: string; value: string }[]>([])
  const [cidade, setCidade] = useState<{ label: string; value: string } | null>(null)
  const [relationship, setRelationship] = useState('')
  const [formData, setFormData] = useState<Schema>()
  const [valorAdicional, setValorAdicional] = useState(0)
  const [valorNovo, setValorNovo] = useState(0)

  useEffect(() => {
    if (estado && props.aluno?.uf != estado) {
      setCidades([])
      setCidade(null)
      setValue('cidade', '')
    } else setValue('cidade', '')
  }, [estado])

  useEffect(() => {
    if (props.aluno) {
      setValue('uf', props.aluno?.uf)
      setValue('cidade', props.aluno?.cidade)
      setValue('nome', props.aluno?.nome)
      setValue('email', props.aluno?.email)
      setValue('telefone', props.aluno?.telefone)
      setValue('nome_escola', props.aluno?.nome_escola)
      setValue('parentesco', props.aluno?.parentesco)
      setRelationship(props.aluno?.parentesco || '')
      setEstado(props.aluno?.uf || '')
      setCidade({ label: props.aluno?.cidade, value: props.aluno?.cidade })
      setValue(
        'data_nascimento',
        props.aluno?.data_nascimento
          ? moment(props.aluno?.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : ''
      )
    }

    const fetchPreview = async () => {
      const ret = await planServices.getPreviewInvoice(
        user.customer_id,
        user.subscription_id,
        user.subscription.status == 'paused' ? 1 : user.subscription.quantity + 1
      )
      if (ret?.total) {
        const novo = parseFloat(
          user.subscription.unit_amount * user.subscription.quantity +
            (user.subscription.status == 'paused' ? 0 : user.subscription.unit_amount)
        )
        setValorAdicional(parseFloat(ret?.total) - novo)
        setValorNovo(novo)
      }
    }

    if (props.mode == 'create') fetchPreview()
  }, [props.aluno])

  const { data: _getAllEstados } = useQuery('uf', userServices.getEstados, {
    onSuccess: (data) => {
      setEstados([])
      if (Array.isArray(data.response)) {
        data?.response.forEach((estado: { nome: string; sigla: string }) => {
          setEstados((prevEstados) => {
            if (Array.isArray(prevEstados)) {
              return [...prevEstados, { label: estado.nome, value: estado.sigla }]
            }
            return prevEstados
          })
        })
      }
    },
    refetchOnWindowFocus: false
  })

  const { data: _getAllCidades } = useQuery(
    ['cidades', estado],
    () => userServices.getCidades(estado),
    {
      enabled: !!estado,
      onSuccess: (data) => {
        setCidades([])
        if (Array.isArray(data?.response)) {
          data?.response.forEach((cidade: { nome: string }) => {
            setCidades((prevCidades) => {
              if (Array.isArray(prevCidades)) {
                return [...prevCidades, { label: cidade.nome, value: cidade.nome }]
              }
              return prevCidades
            })
          })
        }
      }
    }
  )

  // const handleOpen = () => setOpen(true)
  const handleClose = (event: any, reason: any) => {
    if (reason === 'backdropClick') return
    setOpen(false)
    props.setIsOpen(false)
  }
  const handleRelationshipChange = (event: any) => {
    setRelationship(event.target.value as string)
  }

  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] = React.useState(false)
  const [isAddUserConfirmationModalOpen, setIsAddUserConfirmationModalOpen] = React.useState(false)

  const upsertAlunoMutation = useMutation(
    async (props: {
      data: Schema | undefined
      aluno: string | undefined
      responsavel_aluno: string
      estado?: string
      mode: string
    }) => {
      try {
        await alunoServices.upsertAluno(
          {
            ...props.data,
            _id: props.aluno,
            uf: estado,
            data_nascimento: moment(props.data?.data_nascimento).format('DD/MM/YYYY')
          },
          user,
          props.mode
        )
        enqueueSnackbar(`Usuário ${props.mode == 'create' ? 'incluído' : 'salvo'} com sucesso!`, {
          variant: 'success'
        })
        handleClose(null, null)
      } catch (error: any) {
        console.log(error)
        enqueueSnackbar(error?.message, { variant: 'error' })
      }
    }
  )

  const resendMutation = useMutation(
    () => alunoServices.resendCode(props.aluno, props.responsavel_aluno),
    {
      onSuccess: () => {
        enqueueSnackbar('Credenciais reenviadas com sucesso', { variant: 'success' })
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )

  const cancelMutation = useMutation(
    () => alunoServices.cancelarAssinatura(props.aluno, props.responsavel_aluno),
    {
      onSuccess: () => {
        enqueueSnackbar('Assinatura cancelada com sucesso', { variant: 'success' })
        handleClose(null, null)
      },
      onError: (error: any) => {
        if (error?.message) enqueueSnackbar(error.message, { variant: 'error' })
        console.log(error)
      }
    }
  )

  const activateSubsMutation = useMutation(
    () => alunoServices.ativarAssinatura(props.aluno, props.responsavel_aluno),
    {
      onSuccess: () => {
        enqueueSnackbar('Assinatura ativada com sucesso', { variant: 'success' })
        handleClose(null, null)
      },
      onError: (error: any) => {
        if (error?.message) enqueueSnackbar(error.message, { variant: 'error' })
        console.log(error)
      }
    }
  )

  const handleSubmitForm = async (data: Schema) => {
    if (!props.aluno?._id) {
      setFormData(data)
      setIsAddUserConfirmationModalOpen(true)
    } else
      upsertAlunoMutation.mutate({
        data,
        aluno: props.aluno?._id,
        responsavel_aluno: props.responsavel_aluno,
        estado,
        mode: props.mode
      })
  }

  const style = {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
      p: 2
    },
    paper: {
      width: '100%',
      maxWidth: '800px',
      padding: '1rem',
      borderRadius: '8px'
    }
  }

  const CancelSubscriptionModal = (
    props: React.PropsWithChildren<{
      isOpen: boolean
      setIsOpen: (value: boolean) => void
    }>
  ) => {
    const navigate = useNavigate()
    return (
      <Modal open={props.isOpen} onClose={() => props.setIsOpen(true)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '343px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            zIndex: 5
          }}>
          <Avatar
            sx={{
              width: '81px',
              height: '81px',
              backgroundColor: '#FF9800',
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              top: '-5px'
            }}>
            <ErrorOutlineIcon sx={{ fontSize: '2.5rem' }} />
          </Avatar>
          <Typography textAlign={'center'} mt={2} fontWeight={700} fontSize={'1rem'}>
            Tem certeza que deseja cancelar a assinatura?
          </Typography>
          {/* <Typography sx={{ mt: 2 }} textAlign={'center'} fontSize={'0.875rem'}>
            Esta ação não poderá ser desfeita
          </Typography> */}
          <Typography sx={{ mt: 2 }} textAlign={'center'}>
            <Button onClick={() => props.setIsOpen(false)}>Voltar</Button>
            <Button
              variant="contained"
              sx={{ ml: 2, minWidth: '180px' }}
              color="error"
              onClick={() => cancelMutation.mutate()}>
              {cancelMutation.isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                `Cancelar assinatura`
              )}
            </Button>
          </Typography>
        </Paper>
      </Modal>
    )
  }

  const AddUserConfirmationModal = (
    props: React.PropsWithChildren<{
      isOpen: boolean
      setIsOpen: (value: boolean) => void
      valorNovo: number
      valorAdicional: number
      data: Schema | undefined
      responsavel_aluno: string
      estado: string
      mode: string
    }>
  ) => {
    return (
      <Modal open={props.isOpen} onClose={() => props.setIsOpen(true)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '343px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            zIndex: 5
          }}>
          <Avatar
            sx={{
              width: '81px',
              height: '81px',
              backgroundColor: '#03A9F4',
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              top: '-5px'
            }}>
            <ErrorOutlineIcon sx={{ fontSize: '2.5rem' }} />
          </Avatar>
          <Typography textAlign={'center'} mt={2} fontWeight={700} fontSize={'1rem'}>
            Deseja adicionar este usuário à sua assinatura?
          </Typography>
          <Typography sx={{ mt: 2 }} textAlign={'center'} fontSize={'0.875rem'}>
            {props.valorNovo && (
              <span>
                O valor da assinatura passará a ser{' '}
                <span style={{ fontWeight: 700 }}>
                  {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                    props.valorNovo / 100
                  )}
                  .
                </span>
              </span>
            )}
            {!props.valorNovo && (
              <span>
                O valor da assinatura será modificado considerando a adição deste usuário e os dias
                restantes até a renovação.
              </span>
            )}
            {props.valorAdicional && (
              <Typography sx={{ mt: 1 }} display="block">
                Somente na próxima cobrança haverá um adicional de{' '}
                <span style={{ fontWeight: 700 }}>
                  {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(
                    props.valorAdicional / 100
                  )}
                </span>{' '}
                referentes aos dias restantes até a renovação.
              </Typography>
            )}
          </Typography>
          <Typography sx={{ mt: 2 }} textAlign={'center'}>
            <Button
              disabled={upsertAlunoMutation.isLoading}
              color="error"
              onClick={() => props.setIsOpen(false)}>
              Voltar
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ ml: 2, minWidth: '180px', backgroundColor: '#2E7D32' }}
              disabled={upsertAlunoMutation.isLoading}
              onClick={() =>
                upsertAlunoMutation.mutate({
                  data: formData,
                  aluno: undefined,
                  responsavel_aluno: props.responsavel_aluno,
                  estado: props.estado,
                  mode: props.mode
                })
              }>
              {upsertAlunoMutation.isLoading ? (
                <CircularProgress size={24} sx={{ color: 'green' }} />
              ) : (
                `Adicionar usuário`
              )}
            </Button>
          </Typography>
        </Paper>
      </Modal>
    )
  }

  const traduzParentesco = (parentesco = '') => {
    switch (parentesco) {
      case 'FILHO':
        return 'Filho(a)'
      case 'ENTEADO':
        return 'Enteado(a)'
      case 'SOBRINHO':
        return 'Sobrinho(a)'
      case 'NETO':
        return 'Neto(a)'
      case 'IRMAO':
        return 'Irmão(ã)'
      case 'OUTRO':
        return ''
    }
  }

  return (
    <Modal open={open} onClose={handleClose} sx={style.modal}>
      <Paper sx={style.paper}>
        <form
          onSubmit={handleSubmit((data) => {
            handleSubmitForm(data)
          })}>
          <IconButton
            size="small"
            onClick={() => handleClose(null, null)}
            disabled={
              upsertAlunoMutation.isLoading ||
              cancelMutation.isLoading ||
              resendMutation.isLoading ||
              activateSubsMutation.isLoading
            }>
            <KeyboardArrowLeftIcon />
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 700 }}>
              Perfil {props.aluno?.nome ? `de ${props.aluno?.nome}` : ''}
            </Typography>
          </IconButton>
          <Divider sx={{ my: '1rem' }} />
          {props.aluno?.data_limite_de_uso && props.aluno?.usuario_situacao != 'INATIVO' && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', mb: '1rem' }}>
              <AccessTimeIcon sx={{ fontSize: '1.5rem', color: '#FF9800' }} />
              <Typography sx={{ color: '#FF9800', fontSize: '0.8rem', fontWeight: 700 }}>
                Data limite de uso: {moment(props.aluno?.data_limite_de_uso).format('DD/MM/YYYY')}
              </Typography>
            </Box>
          )}
          {/* <Avatar
          variant="rounded"
          sx={{
            width: '164px',
            height: '164px',
            fontSize: '1.5rem',
            position: 'relative',
            overflow: 'initial',
            mb: '2rem'
          }}>
          {props.aluno?.nome &&
            props.aluno?.nome.split(' ')[0].charAt(0) +
              (props.aluno?.nome.split(' ')[1]
                ? props.aluno?.nome.split(' ')[1].charAt(0)?.toUpperCase()
                : '')}
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              bottom: '-8px',
              right: '-8px',
              backgroundColor: 'rgba(104, 113, 146, 1)',
              borderRadius: '50%',
              color: '#fff',
              zIndex: 1
            }}>
            <UploadIcon />
          </IconButton>
          </Avatar> */}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Box display={'flex'} gap={'1rem'} flexDirection={isMobile ? 'column' : 'row'}>
              <TextField
                label="Nome completo"
                {...register('nome')}
                helperText={errors.nome?.message}
                error={!!errors.nome}
                variant="outlined"
                sx={{ width: '100%' }}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Data de nascimento"
                variant="outlined"
                type="date"
                {...register('data_nascimento')}
                helperText={errors.data_nascimento?.message}
                error={!!errors.data_nascimento}
                sx={{ width: isMobile ? '100%' : '70%' }}
              />
            </Box>
            <Box
              id="container"
              display={'flex'}
              gap={'1rem'}
              flexDirection={isMobile ? 'column' : 'row'}>
              <Box id="Dados de contato" sx={{ width: '100%', gap: '1rem' }}>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 700, mb: '0.5rem' }}>
                  Dados de contato
                </Typography>
                <TextField
                  label="E-mail"
                  variant="outlined"
                  {...register('email')}
                  helperText={errors.email?.message}
                  error={!!errors.email}
                  disabled={
                    props.aluno?.usuario && props.aluno?.usuario_situacao != 'RASCUNHO'
                      ? true
                      : false
                  }
                  sx={{ width: '100%' }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    mt: '1rem',
                    flexDirection: isMobile ? 'column' : 'row'
                  }}>
                  <TextField
                    label="Celular"
                    variant="outlined"
                    inputProps={{ maxLength: 11 }}
                    {...register('telefone')}
                    helperText={errors.telefone?.message}
                    error={!!errors.telefone}
                    sx={{ width: isMobile ? '100%' : '50%' }}
                  />

                  <FormControl fullWidth>
                    <InputLabel>Parentesco</InputLabel>
                    <Select
                      labelId="parentesco-select-label"
                      id="parentesco-select"
                      value={relationship ?? props.aluno?.parentesco}
                      label="Parentesco"
                      {...register('parentesco')}
                      error={!!errors.parentesco}
                      onChange={handleRelationshipChange}>
                      <MenuItem value="FILHO">Filho(a)</MenuItem>
                      <MenuItem value="ENTEADO">Enteado(a)</MenuItem>
                      <MenuItem value="SOBRINHO">Sobrinho(a)</MenuItem>
                      <MenuItem value="NETO">Neto(a)</MenuItem>
                      <MenuItem value="IRMAO">Irmão(ã)</MenuItem>
                      <MenuItem value="OUTRO">Outro</MenuItem>
                    </Select>
                    {errors.parentesco && (
                      <Box
                        sx={{
                          color: 'red',
                          fontSize: '0.78rem',
                          marginTop: '0.25rem',
                          fontWeight: 700,
                          pl: '0.55rem'
                        }}>
                        {errors.parentesco.message}
                      </Box>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box id="Localização" sx={{ width: '100%' }}>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 700, mb: '0.5rem' }}>
                  Localização
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '1rem',
                    mb: '1rem',
                    flexDirection: isMobile ? 'column' : 'row'
                  }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-estado"
                    options={estados}
                    sx={{ width: '100%' }}
                    onChange={(_event, value) => {
                      setEstado(value?.value || '')
                    }}
                    value={estados?.find((e) => e.value == estado ?? props.aluno?.uf) || null}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        helperText={errors.uf?.message}
                        error={!!errors.uf}
                        {...register('uf')}
                      />
                    )}
                  />

                  {cidades && (
                    <Autocomplete
                      disablePortal
                      id="combo-box-cidade"
                      disabled={!estado}
                      value={cidade && cidade.value ? cidade : null}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onChange={(_event, value) => {
                        setCidade(value)
                      }}
                      options={cidades}
                      sx={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cidade"
                          helperText={errors.cidade?.message}
                          error={!!errors.cidade}
                          {...register('cidade')}
                        />
                      )}
                    />
                  )}
                </Box>
                <TextField
                  label="Colégio em que estuda"
                  variant="outlined"
                  {...register('nome_escola')}
                  helperText={errors.nome_escola?.message}
                  error={!!errors.nome_escola}
                  sx={{ width: '100%' }}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>
            </Box>
          </Box>
          {props.aluno?.usuario &&
            props.aluno?.usuario_situacao == 'RASCUNHO' &&
            props.aluno?.situacao != 'CANCELADO' && (
              <Typography sx={{ marginTop: '20px', fontSize: '0.8rem', textAlign: 'center' }}>
                {`${
                  relationship ?? props.aluno?.parentesco
                    ? `Seu ${traduzParentesco(relationship ?? props.aluno?.parentesco)} não recebeu`
                    : 'Não foi recebido'
                } o email
                com as credenciais de acesso? `}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    resendMutation.mutate()
                  }}>
                  {resendMutation.isLoading ? <CircularProgress size={14} /> : 'Reenviar'}
                </Link>
              </Typography>
            )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center'
            }}>
            <Button
              variant={'contained'}
              type="submit"
              sx={{ mt: '2rem', minWidth: '180px', backgroundColor: '#687192' }}
              disabled={
                upsertAlunoMutation.isLoading ||
                resendMutation.isLoading ||
                cancelMutation.isLoading ||
                activateSubsMutation.isLoading
              }>
              {props.mode === 'create' && 'Cadastrar'}
              {props.mode != 'create' &&
                (upsertAlunoMutation.isLoading ? (
                  <CircularProgress size={20} sx={{ color: '#687192' }} />
                ) : (
                  'Salvar'
                ))}
            </Button>
            {isMobile && (
              <Button variant="text" color="primary" onClick={() => handleClose(null, null)}>
                Voltar
              </Button>
            )}
            {props.mode == 'edit' && props.aluno?.situacao != 'CANCELADO' && (
              <Button
                variant="text"
                sx={{ color: 'red' }}
                disabled={
                  upsertAlunoMutation.isLoading ||
                  cancelMutation.isLoading ||
                  resendMutation.isLoading
                }
                onClick={() => {
                  setIsCancelSubscriptionModalOpen(true)
                }}>
                Cancelar assinatura
                <DeleteIcon sx={{ ml: '0.5rem' }} />
              </Button>
            )}
            {props.mode == 'edit' && props.aluno?.situacao == 'CANCELADO' && (
              <Button
                variant="text"
                sx={{ color: 'green' }}
                disabled={
                  activateSubsMutation.isLoading ||
                  upsertAlunoMutation.isLoading ||
                  resendMutation.isLoading
                }
                onClick={() => activateSubsMutation.mutate()}>
                {activateSubsMutation.isLoading ? (
                  <CircularProgress size={20} sx={{ color: 'green' }} />
                ) : (
                  'Reativar assinatura'
                )}

                {!activateSubsMutation.isLoading && <ReplayIcon sx={{ ml: '0.5rem' }} />}
              </Button>
            )}
            {props.mode == 'create' && <Box sx={{ mt: '0.25rem' }} />}
            <CancelSubscriptionModal
              isOpen={isCancelSubscriptionModalOpen}
              setIsOpen={setIsCancelSubscriptionModalOpen}
            />
            <AddUserConfirmationModal
              isOpen={isAddUserConfirmationModalOpen}
              setIsOpen={setIsAddUserConfirmationModalOpen}
              valorNovo={valorNovo}
              valorAdicional={valorAdicional}
              data={formData}
              responsavel_aluno={props.responsavel_aluno}
              estado={estado}
              mode={props.mode}
            />
          </Box>
        </form>
      </Paper>
    </Modal>
  )
}
