import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import Base from '../../layout/Base'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useState } from 'react'

import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useMutation } from 'react-query'
import userServices from '../../services/userServices'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'

const schema = z
  .object({
    email: z.string().email('E-mail inválido').min(5),
    password: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
    confirmPassword: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres')
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'As senhas não coincidem',
    path: ['confirmPassword']
  })

export const CreateAccount = () => {
  const router = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  type Schema = z.infer<typeof schema>

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [email, setEmail] = useState('')

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword)
  }

  const createAccountMutation = useMutation(async (data: Schema) => {
    try {
      const response = await userServices.createAccount(data.email, data.password)
      router(`/email-validation?email=${email}&responsavel_aluno=${response?.responsavel_aluno}`)
    } catch (error: any) {
      if (error?.instructions) {
        // percorre o array de instruções e exibe cada uma em um toast
        error.instructions.forEach((instruction: string) => {
          enqueueSnackbar(instruction, { variant: 'error' })
        })
      } else {
        enqueueSnackbar(
          'Não foi possível criar sua conta neste momento. Tente novamente em alguns minutos...',
          { variant: 'error' }
        )
      }
    }
  })

  const handleSubmitForm = async (data: Schema) => {
    await setEmail(data.email)
    createAccountMutation.mutate(data)
  }

  return (
    <Base>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '350px',
          gap: '0.5rem'
        }}>
        <Avatar src="/images/dulce-logo.png" />
        <Typography sx={{ fontSize: '0.8rem' }}>Passo 1 de 4</Typography>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>Crie sua conta</Typography>
        <Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>
          Parabéns! Você está dando seu primeiro passo para cuidar da saúde do seu filho 💜
        </Typography>
        <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
          <TextField
            label="E-mail do responsável"
            variant="outlined"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            sx={{ marginTop: '1rem', width: '100%' }}
          />
          <TextField
            label="Senha"
            variant="outlined"
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            sx={{ marginTop: '1rem', width: '100%' }}
            type={showPassword ? 'text' : 'password'}
            onChange={(event) => {
              event.target.value = event.target.value.replace(/\s/g, '')
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility} edge="end" tabIndex={-1}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            label="Senha"
            variant="outlined"
            {...register('confirmPassword')}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            sx={{ marginTop: '1rem', width: '100%' }}
            type={showConfirmPassword ? 'text' : 'password'}
            onChange={(event) => {
              event.target.value = event.target.value.replace(/\s/g, '')
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleToggleConfirmPasswordVisibility}
                    edge="end"
                    tabIndex={-1}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button variant="contained" sx={{ marginTop: '1rem', width: '100%' }} type="submit">
            {createAccountMutation.isLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Criar conta'
            )}
          </Button>
        </form>
      </Box>
    </Base>
  )
}
