import { Avatar, Box, Button, Typography } from '@mui/material'
import Base from '../../layout/Base'
import logoSmall from '../../assets/logo-small.png'
import { useNavigate } from 'react-router-dom'

export const PasswordResetEmailSent = () => {
  const navigate = useNavigate()
  return (
    <Base>
      <Box
        sx={{
          maxWidth: '368px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}>
        <Avatar src={logoSmall} />
        <Typography sx={{ mt: 2, fontSize: '1.5625rem', fontWeight: 700 }}>
          Verifique seu email
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Enviamos um link com instruções para redefinir sua senha
        </Typography>
        <Button
          variant="text"
          sx={{ mb: 2 }}
          onClick={() => {
            navigate('/')
          }}>
          Voltar para o login
        </Button>
      </Box>
    </Base>
  )
}
