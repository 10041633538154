import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import planServices from '../../services/planServices'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'
import { isValidCPF } from '../../utils/validations'
import { useMutation } from 'react-query'
import { enqueueSnackbar } from 'notistack'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'

const schema = z.object({
  cardNumber: z.string().min(19, 'Número do cartão inválido').nonempty('Número do cartão inválido'),
  cardExpiration: z
    .string()
    .min(1, 'Data de expiração inválida')
    .nonempty('Data de expiração inválida'),
  cardCVV: z.string().min(3, 'CVV inválido'),
  cardName: z.string().min(3, 'Nome inválido'),
  cardCPF: z
    .string()
    .min(11, 'CPF inválido')
    .max(14, 'CPF inválido')
    .nonempty('CPF inválido')
    .refine(isValidCPF, 'CPF inválido')
})

export const ChangePaymentModal = (
  props: React.PropsWithChildren<{
    isOpen: boolean
    setIsOpen: (value: boolean) => void
  }>
) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })
  const { user, updatePaymentMethodLocal } = useContext(AuthContext)

  type Schema = z.infer<typeof schema>

  const style = {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll'
    },
    paper: {
      width: '100%',
      maxWidth: '356px',
      padding: '1rem',
      borderRadius: '8px'
    }
  }

  const formatCardNumber = (value: string) => {
    value = value.replace(/\D/g, '')
    value = value.replace(/(\d{4})(?=\d)/g, '$1 ')
    return value
  }

  const updatePaymentMethodMutation = useMutation(
    (data: Schema) => {
      const payload = {
        responsavel_aluno: user?._id,
        versao_termo: 1,
        nr_cpf: data.cardCPF,
        price_id: user?.subscription?.price_id,
        quantity: user?.subscription?.quantity,
        payment_type: 'card',
        payment_data: {
          number: data.cardNumber,
          exp_month: data.cardExpiration.split('/')[0],
          exp_year: data.cardExpiration.split('/')[1],
          cvc: data.cardCVV
        }
      }

      return planServices.createSubscription(payload)
    },
    {
      onSuccess: (data: any) => {
        enqueueSnackbar('Método de pagamento alterado com sucesso!', {
          variant: 'success'
        })

        const { payment_method } = data?.response?.responsavel?.subscription || {}
        if (payment_method?.last4)
          updatePaymentMethodLocal({
            last4: payment_method.last4,
            exp_month: payment_method.exp_month,
            exp_year: payment_method.exp_year,
            brand: payment_method.brand
          })

        reset()
        props.setIsOpen(false)
      },
      onError: (error: any) => {
        enqueueSnackbar(`${error?.message}`, {
          variant: 'error'
        })
        console.log(error)
      }
    }
  )

  const onSubmit = (data: Schema) => {
    const expirationDate = moment(data.cardExpiration, 'MM/YYYY')
    const today = moment()
    if (expirationDate.isBefore(today)) {
      setError('cardExpiration', { message: 'Data de expiração inválida' })
      return
    }

    updatePaymentMethodMutation.mutate(data)
  }

  return (
    <Modal open={props.isOpen} onClose={() => props.setIsOpen(false)} sx={style.modal}>
      <Paper sx={style.paper}>
        <IconButton size="small" onClick={() => props.setIsOpen(false)}>
          <KeyboardArrowLeftIcon />
          <Typography sx={{ fontSize: '0.8rem', fontWeight: 700 }}>
            Alterar método de pagamento
          </Typography>
        </IconButton>
        <Divider sx={{ my: '1rem' }} />
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 700, mb: 2 }}>
          Informe os dados do seu novo cartão
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Número do Cartão"
            {...register('cardNumber')}
            type="text"
            required
            fullWidth
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CreditCardIcon sx={{ color: '#687192' }} />
                </InputAdornment>
              )
            }}
            onChange={(event) => {
              if (event.target.value.length > 19) {
                event.target.value = event.target.value.slice(0, 19)
              }
              event.target.value = formatCardNumber(event.target.value)
            }}
            error={!!errors.cardNumber}
            helperText={errors.cardNumber?.message}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
            <FormControl sx={{ width: '100%' }}>
              <DatePicker
                label={'Data de Validade'}
                format="MM/YYYY"
                views={['month', 'year']}
                sx={{
                  width: '100%',
                  mt: '0.5rem'
                }}
                onChange={(date: any) => {
                  const { $y, $M } = date
                  setValue('cardExpiration', `${$M + 1}/${$y}`)
                }}
              />
              <FormHelperText error={!!errors.cardExpiration}>
                {errors.cardExpiration?.message}
              </FormHelperText>
            </FormControl>

            {/* <TextField
              label="Data de Validade"
              {...register('cardExpiration')}
              type="text"
              required
              fullWidth
              margin="dense"
              error={!!errors.cardExpiration}
              helperText={errors.cardExpiration?.message}
              onChange={(event) => {
                if (event.target.value.length > 7) {
                  event.target.value = event.target.value.slice(0, 7)
                }
                event.target.value = event.target.value
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d{0,4})/, '$1/$2')
              }}
            /> */}
            <TextField
              label="CVV"
              {...register('cardCVV')}
              type="text"
              required
              fullWidth
              margin="dense"
              error={!!errors.cardCVV}
              helperText={errors.cardCVV?.message}
              onChange={(event) => {
                // Limite de caracteres
                if (event.target.value.length > 3) {
                  event.target.value = event.target.value.slice(0, 3)
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <HelpOutlineIcon sx={{ color: '#687192' }} />
                  </InputAdornment>
                )
              }}
            />
          </Box>

          <TextField
            label="Nome do Titular"
            type="text"
            required
            fullWidth
            margin="dense"
            {...register('cardName')}
            error={!!errors.cardName}
            helperText={errors.cardName?.message}
          />
          <TextField
            label="CPF do Titular"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            required
            fullWidth
            margin="dense"
            {...register('cardCPF')}
            error={!!errors.cardCPF}
            helperText={errors.cardCPF?.message}
            onChange={(event) => {
              if (event.target.value.length > 14) {
                event.target.value = event.target.value.slice(0, 14)
              }
              event.target.value = event.target.value.replace(/\D/g, '')
            }}
          />

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: '1rem' }}
            type="submit"
            disabled={updatePaymentMethodMutation.isLoading}>
            {updatePaymentMethodMutation.isLoading ? (
              <CircularProgress size={24} sx={{ color: '#687192' }} />
            ) : (
              'Salvar'
            )}
          </Button>
        </form>
      </Paper>
    </Modal>
  )
}
