import { Avatar, Box, Button, Typography } from '@mui/material'
import Base from '../../layout/Base'
import logoSad from '../../assets/logo-sad.png'
import { useNavigate } from 'react-router-dom'

export const CancelSubscription = () => {
  const navigate = useNavigate()
  return (
    <Base>
      <Avatar src={logoSad} sx={{ width: '64px', height: '64px', margin: '0 auto' }} />
      <Typography fontSize={'1.5rem'} fontWeight={700} mt={2} textAlign={'center'}>
        Sua assinatura foi cancelada
      </Typography>
      <Typography fontSize={'1rem'} fontWeight={400} maxWidth={'400px'} textAlign={'center'} mt={2}>
        O acesso ao app irá durar até dia <span style={{ fontWeight: 700 }}>12/05/2023</span>, após
        isso todos os acessos serão desativados. Assine novamente para recuperar os acessos.
      </Typography>
      <Button
        variant={'contained'}
        sx={{ width: '346px', mt: 4 }}
        onClick={() => navigate('/cancel-subscription-feedback')}>
        Continuar
      </Button>
    </Base>
  )
}
