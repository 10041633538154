import { Avatar, Box, Button, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext, useState } from 'react'
import { BaseDashboard } from '../../layout/BaseDashboard'
import { PaymentMethods } from '../../components/PaymentMethods'
import { Users } from '../../components/Users'
import logoDulceSmall from '../../assets/logo-small.png'
import logoDulceFull from '../../assets/logo-full.png'
import * as React from 'react'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

export const Dashboard = () => {
  const { signOut, user } = useContext(AuthContext)
  const isMobile = useMediaQuery('(max-width: 673px)')

  const UserList = (props: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <Box>
        <Button
          variant="contained"
          disableElevation
          onClick={handleClick}
          size="small"
          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.40)' }}
          endIcon={<UnfoldMoreIcon sx={{ color: '#2E3855' }} />}>
          <Avatar variant="rounded">
            {user?.nome ? user?.nome?.split(' ')[0]?.charAt(0)?.toUpperCase() : undefined}
          </Avatar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1rem',
              textAlign: 'left'
            }}>
            <Typography sx={{ color: '#2E3855', fontSize: '0.8rem', fontWeight: 700 }}>
              {user?.nome}
            </Typography>
            <Typography sx={{ color: '#2E3855', fontSize: '0.8rem', fontWeight: 400 }}>
              Responsável
            </Typography>
          </Box>
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <MenuItem disabled={true}>{user.email}</MenuItem>
          <MenuItem onClick={signOut}>Sair</MenuItem>
        </Menu>
      </Box>
    )
  }

  return (
    <BaseDashboard>
      <Box
        sx={{
          marginTop: '0.75rem',
          display: 'flex',
          justifyContent: 'space-between',
          width: isMobile ? '95%' : '73%',
          // height: '100%',
          position: 'absolute',
          top: '0'
        }}>
        <img
          src={isMobile ? logoDulceSmall : logoDulceFull}
          style={{
            background: isMobile ? 'white' : 'transparent',
            borderRadius: isMobile ? '50%' : '0',
            width: isMobile ? '44px' : '164px',
            height: isMobile ? '44px' : '29px'
          }}
        />
        <UserList />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isMobile ? 'center' : 'flex-start',
          justifyContent: 'flex-start',
          border: '1px solid #687192',
          borderRadius: '10px',
          padding: '14px',
          minWidth: '70%',
          minHeight: isMobile ? null : '70%',
          marginTop: isMobile ? '1rem' : '4rem',
          backgroundColor: 'rgba(255, 255, 255, 0.80)',
          mt: isMobile ? '170px' : '0'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%'
          }}>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, mb: 2 }}>Assinatura</Typography>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '1rem', mb: 2 }}>
          <PaymentMethods />
          <Users alunos={user?.alunos!} responsavel_aluno={user?._id!} />
        </Box>
      </Box>
    </BaseDashboard>
  )
}
