import api from './api'

export interface IPlanServices {
  getPlans: () => Promise<any>
  createSubscription: (data: any) => Promise<any>
  getPreviewInvoice: (
    customer_id: string,
    subscription_id: string,
    quantity_nova: number
  ) => Promise<any>
}

export const planServices = () => ({
  getPlans: async () => {
    try {
      const { data } = await api.get('/dulce/b2c/planos')
      return data?.response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  createSubscription: async (data: any) => {
    try {
      const { data: response } = await api.post('/dulce/b2c/assinatura', data)
      return response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  getPreviewInvoice: async (
    customer_id: string,
    subscription_id: string,
    quantity_nova: number
  ) => {
    try {
      const { data: response } = await api.get('/dulce/b2c/preview-alteracao-plano', {
        params: { quantity_nova, subscription_id, customer_id }
      })
      return response.response
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export default <IPlanServices>planServices()
