import {
  Avatar,
  Box,
  Typography,
  Button,
  Link,
  CircularProgress,
  useMediaQuery
} from '@mui/material'
import Base from '../../layout/Base'
import { SetStateAction, useState } from 'react'
import OtpInput from 'react18-input-otp' // 'react-otp-input'
import userServices from '../../services/userServices'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'

export const EmailValidation = () => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const [otp, setOtp] = useState('')
  const [error, setError] = useState(null)
  const [codeResended, setCodeResended] = useState(false)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const email = queryParams.get('email')
  const responsavel_aluno = queryParams.get('responsavel_aluno')

  const router = useNavigate()

  const resendMutation = useMutation(() => userServices.resendCode(email as string), {
    onSuccess: () => {
      enqueueSnackbar('Código reenviado com sucesso', { variant: 'success' })
    },
    onError: (error) => {
      console.log(error)
    }
  })

  const codeValidationMutation = useMutation(
    (otp: string) => userServices.codeValidation(email as string, responsavel_aluno as string, otp),
    {
      onSuccess: () => {
        router(`/choose-plan?responsavel_aluno=${responsavel_aluno}`)
      },
      onError: (error: any) => {
        console.log(error)
        setError(error.message)
      }
    }
  )

  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (otp.length === 6) {
      codeValidationMutation.mutate(otp)
    }
  }

  return (
    <Base>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '400px',
          gap: '0.5rem',
          padding: '1rem'
        }}>
        <Avatar src="/images/dulce-logo.png" />
        <Typography sx={{ fontSize: '0.8rem' }}>Passo 2 de 4</Typography>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>Confirme seu email</Typography>
        <Typography sx={{ fontSize: isMobile ? '0.8rem' : '1rem', textAlign: 'center' }}>
          Enviamos um código de 6 dígitos para o email cadastrado, informe-o para prosseguir
        </Typography>
        <form onSubmit={handleSubmit}>
          <OtpInput
            value={otp}
            numInputs={6}
            onChange={(e: SetStateAction<string>) => {
              setOtp(e)
              setError(null)
            }}
            inputStyle={{
              zIndex: 10,
              width: isMobile ? '2rem' : '3rem',
              height: isMobile ? '2rem' : '3rem',
              fontSize: '1.5rem',
              margin: '0 0.3rem',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '12px',
              marginTop: '1.2rem'
            }}
            shouldAutoFocus
          />

          <Typography color="red" sx={{ width: '100%', mt: 1, textAlign: 'center' }}>
            {error}
          </Typography>

          <Button
            variant="contained"
            type="submit"
            disabled={otp.length < 6}
            sx={{
              marginTop: '1rem',
              width: '100%',

              '&:disabled': {
                backgroundColor: '#6871926f',
                color: '#fff'
              }
            }}>
            {codeValidationMutation.isLoading ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : (
              'Próximo'
            )}
          </Button>
        </form>

        <Typography sx={{ fontSize: '0.8rem', textAlign: 'center' }}>
          Não recebeu o código?{' '}
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              resendMutation.mutate()
            }}>
            {resendMutation.isLoading ? <CircularProgress size={14} /> : 'Reenviar'}
          </Link>
        </Typography>
      </Box>
    </Base>
  )
}
