import { Box } from '@mui/material'
import React from 'react'

export const Base = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundImage: 'url(/images/bottom.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: 'contain',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          height: '92px',
          width: '164px',
          backgroundImage: 'url(/images/left-airplane.png)',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          right: '-50px',
          height: '223px',
          width: '122px',
          backgroundImage: 'url(/images/right-airplane.png)',
          backgroundRepeat: 'no-repeat'
        }}
      />

      {children}
    </Box>
  )
}

export default Base
