import { th } from 'date-fns/locale'
import api from './api'
import { enqueueSnackbar } from 'notistack'
import { TUser } from '../types/user'

export interface IUserServices {
  createAccount: (email: string, senha: string) => Promise<any>
  resendCode: (email: string) => Promise<any>
  codeValidation: (email: string, responsavel_aluno: string, code: string) => Promise<any>
  login: (email: string, senha: string) => Promise<any>
  getEstados: () => Promise<any>
  getCidades: (uf: string) => Promise<any>
  updateUser: (data: any) => Promise<any>
  reloadUser: (id: string) => Promise<any>
  resetPassword: (email: string) => Promise<any>
  changePassword: (data: any) => Promise<any>
}

export const userServices = () => ({
  createAccount: async (email: string, senha: string) => {
    try {
      const { data } = await api.post('/dulce/b2c/responsavel/validar-email', { email, senha })
      return data?.response
    } catch (error: any) {
      return await Promise.reject(error?.response?.data)
    }
  },
  resendCode: async (email: string) => {
    try {
      const { data } = await api.post('/dulce/b2c/responsavel/validar-email', {
        email,
        resend_code: true
      })
      return data?.response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  codeValidation: async (email: string, responsavel_aluno: string, code: string) => {
    try {
      const { data } = await api.post('/dulce/b2c/responsavel/validar-codigo/', {
        email,
        responsavel_aluno,
        code
      })
      return data?.response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  login: async (email: string, senha: string) => {
    try {
      const { data } = await api.post('/dulce/b2c/autenticacao', { email, senha })
      return data?.response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  getEstados: async () => {
    try {
      const { data } = await api.get('/cadastros/ufs')
      return data
    } catch (error: any) {
      throw error?.response?.data
    }
  },

  getCidades: async (uf: string) => {
    try {
      const { data } = await api.get(`/cadastros/${uf}/municipios`)
      return data
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  updateUser: async (data: any) => {
    try {
      const { data: response } = await api.put(`/dulce/b2c/responsavel/${data.id}`, data)
      return response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  reloadUser: async (id: string) => {
    try {
      const { data: response } = await api.get(`/dulce/b2c/responsavel/${id}`)
      return response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  resetPassword: async (email: string) => {
    try {
      const { data: response } = await api.post(`/dulce/b2c/esqueci-senha/`, { email })
      return response
    } catch (error: any) {
      throw error?.response?.data
    }
  },
  changePassword: async (data: any) => {
    try {
      const { data: response } = await api.post(`/dulce/b2c/alterar-senha/`, data)
      return response
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export default <IUserServices>userServices()
