import Base from '../../layout/Base'
import { Box, Button, IconButton, Typography } from '@mui/material'
import Lottie from 'react-lottie'
import animationData from '../../assets/animations/payment-sucess.json'
import { useNavigate } from 'react-router-dom'

export const PaymentSucess = () => {
  const queryParams = new URLSearchParams(location.search)
  const responsavel_aluno = queryParams.get('responsavel_aluno')
  const cpf = queryParams.get('cpf')
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const navigate = useNavigate()

  return (
    <Base>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '400px',
          gap: '0.5rem',
          padding: '1rem'
        }}>
        <Lottie options={defaultOptions} height={100} width={100} />
        <Typography sx={{ fontSize: '1.56rem', fontWeight: 700, textAlign: 'center' }}>
          Pagamento confirmado!
        </Typography>
        <Typography sx={{ fontSize: '0.875rem', textAlign: 'center' }}>
          Após concluir seu cadastro, acesse a área de pais para incluir seus usuários para que
          recebam as credenciais de acesso ao app
        </Typography>
        <Button
          variant="contained"
          sx={{ marginTop: '1rem', width: '100%', backgroundColor: '#687192' }}
          onClick={() => {
            navigate(`/parent-information?responsavel_aluno=${responsavel_aluno}&cpf=${cpf}`)
          }}>
          Concluir meu cadastro
        </Button>
      </Box>
    </Base>
  )
}
