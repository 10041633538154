import { ReactNode, useEffect, useState } from 'react'
import { TUser } from '../../types/user'
import { AuthContext } from './AuthContext'
import userServices from '../../services/userServices'

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<TUser | null>(null)
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const localToken = localStorage.getItem('@token')
    const localUser = localStorage.getItem('@user')
    if (localToken) {
      setToken(localToken)
    }
    if (localUser) {
      setUser(JSON.parse(localUser))
    }
  }, [])

  const signIn = async (email: string, password: string) => {
    const response = await userServices.login(email, password)

    if (response.responsavel) {
      setUser(response.responsavel)
      localStorage.setItem('@user', JSON.stringify(response.responsavel))
    }

    if (response.access_token) {
      setToken(response.access_token)
      localStorage.setItem('@token', response.access_token)
      return true
    }

    return false
  }

  const signOut = async () => {
    // await api.signOut()
    localStorage.removeItem('@token')
    localStorage.removeItem('@user')
    setUser(null)
    setToken(null)
  }

  const reloadUser = async (usuario_responsavel: string) => {
    const data = await userServices.reloadUser(usuario_responsavel)
    if (data?.response) {
      setUser(data.response)
      localStorage.setItem('@user', JSON.stringify(data.response))
    }
  }

  const updateUserLocal = ({
    nome,
    uf,
    cidade,
    telefone,
    parentesco
  }: {
    nome: string
    uf: string
    cidade: string
    telefone: string
    parentesco: string
  }) => {
    const localUser = localStorage.getItem('@user')
    if (localUser) {
      const user = JSON.parse(localUser)
      user.nome = nome
      user.uf = uf
      user.cidade = cidade
      user.telefone = telefone
      user.parentesco = parentesco
      localStorage.setItem('@user', JSON.stringify(user))
      setUser(user)
    }
  }

  const updatePaymentMethodLocal = ({
    last4,
    exp_month,
    exp_year,
    brand
  }: {
    last4: string
    exp_month: string
    exp_year: string
    brand: string
  }) => {
    const localUser = localStorage.getItem('@user')
    if (localUser) {
      const user = JSON.parse(localUser)
      user.subscription.payment_method.last4 = last4
      user.subscription.payment_method.exp_month = exp_month
      user.subscription.payment_method.exp_year = exp_year
      user.subscription.payment_method.brand = brand
      localStorage.setItem('@user', JSON.stringify(user))
      setUser(user)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setToken,
        signed: !!token,
        signIn,
        signOut,
        updateUserLocal,
        reloadUser,
        updatePaymentMethodLocal
      }}>
      {children}
    </AuthContext.Provider>
  )
}
