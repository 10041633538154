import { Avatar, Box, Button, CircularProgress, TextField, Typography } from '@mui/material'
import Base from '../../layout/Base'
import logoSmall from '../../assets/logo-small.png'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useQuery, useMutation } from 'react-query'
import userServices from '../../services/userServices'

const schema = z.object({
  email: z.string().email('E-mail inválido').min(5, 'E-mail inválido').max(255, 'E-mail inválido')
})

export const PasswordReset = () => {
  const navigate = useNavigate()
  type Schema = z.infer<typeof schema>
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const onSubmit = (data: Schema) => {
    resetPasswordMutation.mutate(data)
  }

  const resetPasswordMutation = useMutation(
    (data: Schema) => {
      return userServices.resetPassword(data.email)
    },
    {
      onSuccess: () => {
        navigate('/password-reset-email-sent')
      },
      onError: (error: any) => {
        console.log(error)
      }
    }
  )

  return (
    <Base>
      <Box
        sx={{
          maxWidth: '368px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2
        }}>
        <Avatar src={logoSmall} />
        <Typography sx={{ mt: 2, fontSize: '1.5625rem', fontWeight: 700 }}>
          Recupere sua senha
        </Typography>
        <Typography sx={{ mb: 2, fontSize: '0.875rem', fontWeight: 400 }}>
          Por favor, informe seu email cadastrado, te enviaremos um link para você redefinir sua
          senha
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            label="E-mail"
            variant="outlined"
            sx={{ width: '100%' }}
            {...register('email', { required: true })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Button variant="contained" sx={{ mt: 2, width: '100%' }} type="submit">
            {resetPasswordMutation.isLoading ? (
              <CircularProgress size={24} sx={{ color: '#fff' }} />
            ) : (
              'Enviar'
            )}
          </Button>
        </form>
        <Button
          variant="text"
          sx={{ mt: 2, width: '100%' }}
          onClick={() => {
            navigate('/')
          }}>
          Voltar para o login
        </Button>
      </Box>
    </Base>
  )
}
