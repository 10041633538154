import {
  Avatar,
  Box,
  Typography,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  useMediaQuery
} from '@mui/material'
import Base from '../../layout/Base'
import { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import planServices from '../../services/planServices'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

export const ChoosePlan = () => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const queryParams = new URLSearchParams(location.search)
  const responsavel_aluno = queryParams.get('responsavel_aluno')
  const router = useNavigate()
  const [count, setCount] = useState(1)
  const [selected, setSelected] = useState({
    id: 0,
    nome: 'Mensal',
    valor: 0
  })

  const { data, isLoading } = useQuery('plans', planServices.getPlans)

  useEffect(() => {
    if (data && data.length > 0) {
      setSelected(data[0])
    }
  }, [data])

  const Counter = ({ event }: { event: () => void }) => {
    const handleAdd = () => {
      setCount(count + 1)
    }

    const handleRemove = () => {
      if (count > 1) {
        setCount(count - 1)
      }
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        {count !== 1 ? (
          <Button sx={{ minWidth: '0px', width: '24px', height: '24px' }} onClick={handleRemove}>
            <Avatar sx={{ width: '24px', height: '24px', backgroundColor: '#9C7C9F' }}>
              <RemoveIcon />
            </Avatar>
          </Button>
        ) : (
          <Button sx={{ minWidth: '0px', width: '24px', height: '24px' }} disabled>
            <Avatar sx={{ width: '24px', height: '24px', backgroundColor: '#9c7c9f86' }}>
              <RemoveIcon />
            </Avatar>
          </Button>
        )}
        <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>{count}</Typography>
        <Button sx={{ minWidth: '0px', width: '24px', height: '24px' }} onClick={handleAdd}>
          <Avatar sx={{ width: '24px', height: '24px', backgroundColor: '#9C7C9F' }}>
            <AddIcon />
          </Avatar>
        </Button>
      </Box>
    )
  }

  interface PlanCardProps {
    title: string
    price: number
    description: string
    selected?: boolean
    event?: () => void
    discount?: number
  }

  const PlanCard = ({ title, price, description, selected, event, discount }: PlanCardProps) => {
    return (
      <Card>
        <CardActionArea
          sx={{
            border: selected ? '1px solid #9C7C9F' : '',
            backgroundColor: selected ? '#9c7c9f3b' : ''
          }}
          onClick={event}>
          <CardContent
            sx={{
              minWidth: '130px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '0.5rem',
              padding: '1rem'
            }}>
            <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>{title}</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'center',
                marginTop: '-50px'
              }}>
              <Typography sx={{ fontSize: '0.8125rem', mt: 1, color: '#2E7D32 ' }}>
                {discount}
              </Typography>
              <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>
                <span
                  style={{
                    fontWeight: 'normal',
                    fontSize: '0.8rem',
                    marginRight: 4,
                    verticalAlign: 'super'
                  }}>
                  R$
                </span>
                <span style={{ fontWeight: 'bold', fontSize: '1.8rem' }}>
                  {Math.floor(price).toLocaleString('pt-BR')}
                </span>
                <span style={{ fontWeight: 'normal', fontSize: '1rem' }}>
                  {price.toLocaleString('pt-BR', { minimumFractionDigits: 2 }).slice(-3)}
                </span>
              </Typography>
              <Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>{description}</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  return (
    <Base>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem'
        }}>
        <Avatar src="/images/dulce-logo.png" />
        <Typography sx={{ fontSize: '0.8rem' }}>Passo 3 de 4</Typography>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, mb: '30px' }}>
          Escolha seu plano
        </Typography>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              width: '100%'
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginBottom: '1rem'
              }}>
              <Typography sx={{ fontSize: '1rem', textAlign: 'center', fontWeight: 700 }}>
                Usuários:
              </Typography>
              <Counter event={() => setCount(count)} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                width: '100%',
                justifyContent: 'center',
                flexDirection: isMobile ? 'column' : 'row'
              }}>
              {data &&
                data.map((plan: any) => (
                  <PlanCard
                    key={plan.id}
                    title={plan?.nome}
                    price={plan.valor * count}
                    description={`para ${count} ${count > 1 ? 'usuários' : 'usuário'}`}
                    selected={selected?.id === plan.id}
                    event={() => setSelected(plan)}
                    discount={plan.metadata.desconto ? plan.metadata.desconto : null}
                  />
                ))}
            </Box>

            <Button
              variant="contained"
              sx={{ marginTop: '1rem', width: '100%' }}
              onClick={() => {
                // console.log(selected, count)
                router(
                  `/payment-data?plan=${selected.id}&price=${selected?.valor}&count=${count}&responsavel_aluno=${responsavel_aluno}&plantitle=${selected?.nome}`
                )
              }}>
              Próximo
            </Button>
          </Box>
        )}
      </Box>
    </Base>
  )
}
