import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import Base from '../../layout/Base'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import userServices from '../../services/userServices'
import { useMutation } from 'react-query'
import { AuthContext } from '../../contexts/auth/AuthContext'

const schema = z.object({
  email: z.string().email('E-mail inválido').min(5),
  password: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres')
})

export const Login = () => {
  const navigate = useNavigate()
  const { signIn } = useContext(AuthContext)
  const [showPassword, setShowPassword] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  type Schema = z.infer<typeof schema>

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const loginMutation = useMutation(async (data: Schema) => {
    try {
      const response = await signIn(data.email, data.password)
      navigate('/dashboard')
    } catch (error) {
      if (error) {
        setError('password', {
          type: 'manual',
          message: 'E-mail ou senha incorretos'
        })
      }
    }
  })

  const handleSubmitForm = async (data: Schema) => {
    loginMutation.mutate(data)
  }

  return (
    <Base>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '350px',
          p: 2
        }}>
        <Avatar src="/images/dulce-logo.png" />
        <Typography sx={{ fontSize: '1.5rem', fontWeight: 700 }}>Bem-vindo ao Dulce</Typography>
        <Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>
          Entre na sua conta para acessar a área para pais
        </Typography>
        <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
          <TextField
            label="E-mail"
            variant="outlined"
            sx={{ marginTop: '1rem', width: '100%' }}
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            label="Senha"
            variant="outlined"
            sx={{ marginTop: '1rem', width: '100%' }}
            type={showPassword ? 'text' : 'password'}
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            sx={{ marginTop: '1rem', width: '100%' }}
            type="submit"
            disabled={false}>
            {loginMutation.isLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Entrar'
            )}
          </Button>
        </form>
        <Typography
          onClick={() => {
            navigate('/password-reset')
          }}
          sx={{
            fontSize: '0.875rem',
            marginTop: '1rem',
            color: '#687192',
            fontWeight: 700,
            cursor: 'pointer'
          }}>
          Esqueci minha senha
        </Typography>
        <Typography sx={{ fontSize: '0.875rem', marginTop: '1rem', color: '#687192' }}>
          Não tem uma conta?{' '}
          <span
            onClick={() => {
              navigate('/create-account')
            }}
            style={{ color: '#1976D2', textDecoration: 'underline', cursor: 'pointer' }}>
            Assinar
          </span>
        </Typography>
      </Box>
    </Base>
  )
}
