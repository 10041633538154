import ThemeProvider from './theme/ThemeProvider'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from './routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthProvider } from './contexts/auth/AuthProvider'
import { SnackbarProvider } from 'notistack'

function App() {
  const queryClient = new QueryClient()
  return (
    <ThemeProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </SnackbarProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
